import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const ChevronUp = ({ title = 'Chevron up', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <g stroke="currentColor" fill="none">
      <path d="M30 25L20 15L10 25" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </BaseSvgExtend>
);

export default ChevronUp;
