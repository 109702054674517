import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Refresh = ({ title = 'Refresh', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path
      d="M38.3333 6.66663V16.6666M38.3333 16.6666H28.3333M38.3333 16.6666L30.6 9.39996C28.8088 7.60782 26.5927 6.29864 24.1586 5.59459C21.7246 4.89053 19.1518 4.81455 16.6805 5.37373C14.2091 5.93291 11.9197 7.10902 10.0258 8.79233C8.13189 10.4756 6.69528 12.6113 5.85 15M1.66666 33.3333V23.3333M1.66666 23.3333H11.6667M1.66666 23.3333L9.4 30.6C11.1912 32.3921 13.4073 33.7013 15.8413 34.4053C18.2754 35.1094 20.8482 35.1854 23.3195 34.6262C25.7909 34.067 28.0803 32.8909 29.9742 31.2076C31.8681 29.5243 33.3047 27.3887 34.15 25"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseSvgExtend>
);

export default Refresh;
