import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Facebook = ({ title = 'Facebook', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path
      d="M21.125 37.325V23.9375H16.625V18.7625H21.125V14.9375C21.125 10.4375 23.825 8.07495 27.875 8.07495C29.7875 8.07495 31.3625 8.18745 31.925 8.29995V12.9125H29.1125C26.975 12.9125 26.525 13.925 26.525 15.5V18.875H31.7L31.025 24.05H26.525V37.4375L21.125 37.325Z"
      fill="currentColor"
    />
    <path
      d="M3.125 2H36.875C37.55 2 38 2.45 38 3.125V36.875C38 37.55 37.55 38 36.875 38H3.125C2.45 38 2 37.55 2 36.875V3.125C2 2.45 2.45 2 3.125 2Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
    />
  </BaseSvgExtend>
);

export default Facebook;
