import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const AlertCircle = ({ title = 'Alert circle', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <g>
      <path
        d="M20 17.1001C20.5523 17.1001 21 17.5478 21 18.1001V27.1001C21 27.6524 20.5523 28.1001 20 28.1001C19.4477 28.1001 19 27.6524 19 27.1001V18.1001C19 17.5478 19.4477 17.1001 20 17.1001Z"
        fill="currentColor"
      />
      <path
        d="M20 11.3333C19.0795 11.3333 18.3333 12.0794 18.3333 12.9999C18.3333 13.9204 19.0795 14.6666 20 14.6666H20.0166C20.9371 14.6666 21.6833 13.9204 21.6833 12.9999C21.6833 12.0794 20.9371 11.3333 20.0166 11.3333H20Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.0098 7.99572C25.4187 1.30152 14.5811 1.30156 7.99004 7.99583C1.40349 14.5855 1.4033 25.4139 7.98949 32.0038C13.3942 37.5113 21.641 38.4763 28.1069 34.9178L28.1788 34.8929L28.2062 34.8834L28.2256 34.8769L28.2508 34.8687L34.8471 36.6677L34.8575 36.6702C35.353 36.7941 35.9024 36.6869 36.2946 36.2947C36.6868 35.9025 36.794 35.3531 36.6701 34.8576L36.6676 34.8472L34.8622 28.2275L34.865 28.2178L34.8693 28.2051C34.8738 28.1926 34.8794 28.1792 34.8859 28.165C38.4871 21.7807 37.5189 13.5072 32.0098 7.99572ZM30.5872 9.40153C24.779 3.49962 15.221 3.49962 9.41273 9.40153L9.40712 9.40723C3.59764 15.2167 3.59762 24.7835 9.40709 30.593L9.41396 30.5999C14.187 35.4665 21.4923 36.3153 27.2131 33.1266L27.2949 33.081L27.3838 33.0514C27.4014 33.0455 27.4237 33.0372 27.4502 33.0272C27.6699 32.9449 28.1822 32.7529 28.7844 32.9412L34.4998 34.4999L32.9411 28.7848C32.7318 28.1169 32.9685 27.527 33.1056 27.2529L33.1172 27.2297L33.13 27.2071C36.3095 21.5962 35.4733 14.2876 30.5929 9.4072L30.5872 9.40153Z"
        fill="currentColor"
      />
    </g>
  </BaseSvgExtend>
);

export default AlertCircle;
