import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const BroadbandPause = ({ title = 'BroadbandPause', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path
      fill="currentColor"
      d="M19.5 4c-2.813 0-5.568.968-7.882 2.786a1 1 0 1 1-1.236-1.572C13.035 3.129 16.222 2 19.5 2c3.279 0 6.465 1.13 9.118 3.214a1 1 0 1 1-1.236 1.572C25.068 4.968 22.313 4 19.5 4ZM14.596 11.803A9.06 9.06 0 0 1 20 10a9.06 9.06 0 0 1 5.404 1.803 1 1 0 0 0 1.192-1.606A11.06 11.06 0 0 0 20 8c-2.368 0-4.674.77-6.596 2.197a1 1 0 1 0 1.192 1.606ZM30.98 23.271a.667.667 0 0 0-.666.667v3.667a.667.667 0 0 0 1.334 0v-3.667a.667.667 0 0 0-.667-.666ZM33.814 23.938a.667.667 0 0 1 1.334 0v3.667a.667.667 0 1 1-1.334 0v-3.667Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.751 10.34a1 1 0 0 0-1.502 1.32l11.29 12.84H6a3 3 0 0 0-3 3V34a3 3 0 0 0 3 3h27.5a3 3 0 0 0 3-3v-2.234a7.196 7.196 0 0 1-2 .832V34a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-6.5a1 1 0 0 1 1-1h19.576a7.24 7.24 0 0 0 14.417-.928A7.238 7.238 0 0 0 25.596 24.5h-3.578l11.72-12.825a1 1 0 1 0-1.476-1.35L19.764 24.002 7.751 10.34Zm30.794 15.232a5.79 5.79 0 1 1-11.58 0 5.79 5.79 0 0 1 11.58 0Z"
    />
    <path
      fill="currentColor"
      d="M21.415 14.586a1.998 1.998 0 0 1 0 2.829 1.998 1.998 0 0 1-2.828 0 1.998 1.998 0 0 1 0-2.829 1.998 1.998 0 0 1 2.828 0Z"
    />
  </BaseSvgExtend>
);

export default BroadbandPause;
