import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const PhoneNumber = ({ title = 'Phone number', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
      <path d="M4.8 1C2.70132 1 1 2.70132 1 4.8V8.6C1 10.6987 2.70132 12.4 4.8 12.4H8.6C10.6987 12.4 12.4 10.6987 12.4 8.6V4.8C12.4 2.70132 10.6987 1 8.6 1H4.8ZM10.5 4.8C10.5 3.75066 9.64934 2.9 8.6 2.9H4.8C3.75066 2.9 2.9 3.75066 2.9 4.8V8.6C2.9 9.64934 3.75066 10.5 4.8 10.5H8.6C9.64934 10.5 10.5 9.64934 10.5 8.6V4.8Z" />
      <path d="M4.8 14.3C2.70132 14.3 1 16.0014 1 18.1V21.9C1 23.9987 2.70132 25.7 4.8 25.7H8.6C10.6987 25.7 12.4 23.9987 12.4 21.9V18.1C12.4 16.0014 10.6987 14.3 8.6 14.3H4.8ZM10.5 18.1C10.5 17.0507 9.64934 16.2 8.6 16.2H4.8C3.75066 16.2 2.9 17.0507 2.9 18.1V21.9C2.9 22.9494 3.75066 23.8 4.8 23.8H8.6C9.64934 23.8 10.5 22.9494 10.5 21.9V18.1Z" />
      <path d="M1 31.4001C1 29.3014 2.70132 27.6001 4.8 27.6001H8.6C10.6987 27.6001 12.4 29.3014 12.4 31.4001V35.2001C12.4 37.2988 10.6987 39.0001 8.6 39.0001H4.8C2.70132 39.0001 1 37.2988 1 35.2001V31.4001ZM2.9 31.4001C2.9 30.3508 3.75066 29.5001 4.8 29.5001H8.6C9.64934 29.5001 10.5 30.3508 10.5 31.4001V35.2001C10.5 36.2494 9.64934 37.1001 8.6 37.1001H4.8C3.75066 37.1001 2.9 36.2494 2.9 35.2001V31.4001Z" />
      <path d="M18.1 1C16.0013 1 14.3 2.70132 14.3 4.8V8.6C14.3 10.6987 16.0013 12.4 18.1 12.4H21.9C23.9987 12.4 25.7 10.6987 25.7 8.6V4.8C25.7 2.70132 23.9987 1 21.9 1H18.1ZM23.8 4.8C23.8 3.75066 22.9493 2.9 21.9 2.9H18.1C17.0507 2.9 16.2 3.75066 16.2 4.8V8.6C16.2 9.64934 17.0507 10.5 18.1 10.5H21.9C22.9493 10.5 23.8 9.64934 23.8 8.6V4.8Z" />
      <path d="M14.3 18.1C14.3 16.0014 16.0013 14.3 18.1 14.3H21.9C23.9987 14.3 25.7 16.0014 25.7 18.1V21.9C25.7 23.9987 23.9987 25.7 21.9 25.7H18.1C16.0013 25.7 14.3 23.9987 14.3 21.9V18.1ZM16.2 18.1C16.2 17.0507 17.0507 16.2 18.1 16.2H21.9C22.9493 16.2 23.8 17.0507 23.8 18.1V21.9C23.8 22.9494 22.9493 23.8 21.9 23.8H18.1C17.0507 23.8 16.2 22.9494 16.2 21.9V18.1Z" />
      <path d="M18.1 27.6001C16.0013 27.6001 14.3 29.3014 14.3 31.4001V35.2001C14.3 37.2988 16.0013 39.0001 18.1 39.0001H21.9C23.9987 39.0001 25.7 37.2988 25.7 35.2001V31.4001C25.7 29.3014 23.9987 27.6001 21.9 27.6001H18.1ZM23.8 31.4001C23.8 30.3508 22.9493 29.5001 21.9 29.5001H18.1C17.0507 29.5001 16.2 30.3508 16.2 31.4001V35.2001C16.2 36.2494 17.0507 37.1001 18.1 37.1001H21.9C22.9493 37.1001 23.8 36.2494 23.8 35.2001V31.4001Z" />
      <path d="M27.6 4.8C27.6 2.70132 29.3013 1 31.4 1H35.2C37.2987 1 39 2.70132 39 4.8V8.6C39 10.6987 37.2987 12.4 35.2 12.4H31.4C29.3013 12.4 27.6 10.6987 27.6 8.6V4.8ZM29.5 4.8C29.5 3.75066 30.3507 2.9 31.4 2.9H35.2C36.2493 2.9 37.1 3.75066 37.1 4.8V8.6C37.1 9.64934 36.2493 10.5 35.2 10.5H31.4C30.3507 10.5 29.5 9.64934 29.5 8.6V4.8Z" />
      <path d="M31.4 14.3C29.3013 14.3 27.6 16.0014 27.6 18.1V21.9C27.6 23.9987 29.3013 25.7 31.4 25.7H35.2C37.2987 25.7 39 23.9987 39 21.9V18.1C39 16.0014 37.2987 14.3 35.2 14.3H31.4ZM37.1 18.1C37.1 17.0507 36.2493 16.2 35.2 16.2H31.4C30.3507 16.2 29.5 17.0507 29.5 18.1V21.9C29.5 22.9494 30.3507 23.8 31.4 23.8H35.2C36.2493 23.8 37.1 22.9494 37.1 21.9V18.1Z" />
      <path d="M27.6 31.4001C27.6 29.3014 29.3013 27.6001 31.4 27.6001H35.2C37.2987 27.6001 39 29.3014 39 31.4001V35.2001C39 37.2988 37.2987 39.0001 35.2 39.0001H31.4C29.3013 39.0001 27.6 37.2988 27.6 35.2001V31.4001ZM29.5 31.4001C29.5 30.3508 30.3507 29.5001 31.4 29.5001H35.2C36.2493 29.5001 37.1 30.3508 37.1 31.4001V35.2001C37.1 36.2494 36.2493 37.1001 35.2 37.1001H31.4C30.3507 37.1001 29.5 36.2494 29.5 35.2001V31.4001Z" />
    </g>
  </BaseSvgExtend>
);

export default PhoneNumber;
