import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Card = ({ title = 'Card', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <g fill="currentColor">
      <path d="M8 27C7.44772 27 7 27.4477 7 28C7 28.5523 7.44772 29 8 29V27ZM15 29C15.5523 29 16 28.5523 16 28C16 27.4477 15.5523 27 15 27V29ZM4 33C3.45228 33 3 32.5477 3 32H1C1 33.6523 2.34772 35 4 35V33ZM3 32V8H1V32H3ZM3 8C3 7.45228 3.45228 7 4 7V5C2.34772 5 1 6.34772 1 8H3ZM4 7H36V5H4V7ZM36 7C36.5477 7 37 7.45228 37 8H39C39 6.34772 37.6523 5 36 5V7ZM37 8V32H39V8H37ZM37 32C37 32.5477 36.5477 33 36 33V35C37.6523 35 39 33.6523 39 32H37ZM36 33H4V35H36V33ZM2 13H38V11H2V13ZM8 29H15V27H8V29Z" />
    </g>
  </BaseSvgExtend>
);

export default Card;
