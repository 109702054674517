import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Cross = ({ title = 'Cross', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <g stroke="currentColor" fill="none">
      <path d="M30 10L10 30M10 10L30 30" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </BaseSvgExtend>
);

export default Cross;
