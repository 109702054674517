import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Linkedin = ({ title = 'Linkedin', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" fill="none" title={title} {...rest}>
    <g fill="currentColor">
      <path d="M14.0375 14.0374H9.53749V30.5749H14.0375V14.0374Z" />
      <path d="M11.7875 12.125C13.25 12.125 14.375 10.8875 14.375 9.53745C14.375 8.18745 13.25 6.94995 11.7875 6.94995C10.325 6.94995 9.2 8.18745 9.2 9.53745C9.2 10.8875 10.325 12.125 11.7875 12.125Z" />
      <path d="M32.0375 30.4624H27.5375V22.0249C27.5375 16.9624 21.575 17.4124 21.575 22.0249V30.4624H17.075V14.0374H21.575V16.6249C23.7125 12.6874 32.0375 12.4624 32.0375 20.3374V30.4624Z" />
      <path
        d="M3.125 2H36.875C37.55 2 38 2.45 38 3.125V36.875C38 37.55 37.55 38 36.875 38H3.125C2.45 38 2 37.55 2 36.875V3.125C2 2.45 2.45 2 3.125 2Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
      />
    </g>
  </BaseSvgExtend>
);

export default Linkedin;
