import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const VoicePause = ({ title = 'VoicePause', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path
      fill="currentColor"
      d="M17.619 32.667c0-.5.405-.905.905-.905h3.619a.905.905 0 1 1 0 1.81h-3.62a.905.905 0 0 1-.904-.905ZM28.75 19a.667.667 0 0 1 1.333 0v3.667a.667.667 0 1 1-1.333 0V19ZM32.917 18.333a.667.667 0 0 0-.667.667v3.667a.667.667 0 0 0 1.333 0V19a.667.667 0 0 0-.666-.667Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.428 20.634a7.24 7.24 0 0 0-6.333-7.183V3.714A2.72 2.72 0 0 0 29.381 1H11.286A2.72 2.72 0 0 0 8.57 3.714v32.572A2.72 2.72 0 0 0 11.286 39H29.38a2.72 2.72 0 0 0 2.714-2.714v-8.47a7.24 7.24 0 0 0 6.333-7.182ZM11.286 2.81a.91.91 0 0 0-.905.904v32.572c0 .495.41.904.905.904H29.38a.91.91 0 0 0 .905-.904v-8.47a7.24 7.24 0 0 1 0-14.364V3.713a.91.91 0 0 0-.905-.904H11.286ZM36.98 20.634a5.79 5.79 0 1 1-11.581-.001 5.79 5.79 0 0 1 11.58 0Z"
    />
  </BaseSvgExtend>
);

export default VoicePause;
