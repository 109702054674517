import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const User = ({ title = 'User', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" fill="none" title={title} {...rest}>
    <path
      d="M4 36C4 28.8 9 22.8 15.3 20.6C15.9 20.4 16 19.1 15.5 18.8C13.4 17.3 12 14.7 12 12C12 7.5 15.5 4 20 4C24.5 4 27.9 7.5 27.9 12C27.9 14.7 26.5 17.3 24.3 18.8C23.8 19.2 23.9 20.4 24.5 20.6C30.8 22.7 35.8 28.7 35.8 36"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </BaseSvgExtend>
);

export default User;
