import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const StorageCard = ({ title = 'Storage card', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" fill="none" title={title} {...rest}>
    <g fill="currentColor">
      <path d="M14.9559 25.1959C14.0973 25.1959 13.3226 24.9859 12.6319 24.5659C11.9413 24.1459 11.3999 23.5533 11.0079 22.7879C10.6159 22.0226 10.4199 21.1453 10.4199 20.1559C10.4199 19.1293 10.6159 18.2099 11.0079 17.3979C11.4093 16.5859 11.9786 15.9513 12.7159 15.4939C13.4626 15.0273 14.3353 14.7939 15.3339 14.7939C16.3326 14.7939 17.1866 15.0506 17.8959 15.5639C18.6053 16.0773 19.1046 16.8053 19.3939 17.7479L17.8119 18.7279C17.6626 17.9346 17.3733 17.3139 16.9439 16.8659C16.5146 16.4179 15.9639 16.1939 15.2919 16.1939C14.6946 16.1939 14.1766 16.3666 13.7379 16.7119C13.2993 17.0479 12.9633 17.5146 12.7299 18.1119C12.4966 18.7093 12.3799 19.3906 12.3799 20.1559C12.3799 20.9119 12.5013 21.5653 12.7439 22.1159C12.9959 22.6666 13.3319 23.0866 13.7519 23.3759C14.1719 23.6559 14.6386 23.7959 15.1519 23.7959C15.8799 23.7959 16.4633 23.5626 16.9019 23.0959C17.3499 22.6199 17.6066 22.0226 17.6719 21.3039L17.6859 21.0799H15.2499V19.6519H19.3099V23.1799C19.3099 23.8986 19.3379 24.5053 19.3939 24.9999H17.8399V23.1239C17.6346 23.7773 17.2799 24.2859 16.7759 24.6499C16.2813 25.0139 15.6746 25.1959 14.9559 25.1959Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9234 24.6779C26.3541 24.8926 25.7287 24.9999 25.0474 24.9999H20.8754V14.9899H25.1594C26.1207 14.9899 26.9187 15.1906 27.5534 15.5919C28.1881 15.9839 28.5054 16.5626 28.5054 17.3279C28.5054 17.9999 28.2814 18.5413 27.8334 18.9519C27.3947 19.3533 26.8021 19.6333 26.0554 19.7919C27.8754 20.0066 28.7854 20.7719 28.7854 22.0879C28.7854 22.7133 28.6174 23.2453 28.2814 23.6839C27.9454 24.1226 27.4927 24.4539 26.9234 24.6779ZM24.7814 23.5859C25.3787 23.5859 25.8687 23.4413 26.2514 23.1519C26.6341 22.8626 26.8254 22.4659 26.8254 21.9619C26.8254 21.4859 26.6387 21.1266 26.2654 20.8839C25.8921 20.6413 25.4161 20.5199 24.8374 20.5199H22.7234V23.5859H24.7814ZM24.6834 19.1059C25.2154 19.1059 25.6587 18.9753 26.0134 18.7139C26.3681 18.4526 26.5454 18.0886 26.5454 17.6219C26.5454 17.2113 26.3727 16.9079 26.0274 16.7119C25.6821 16.5066 25.2387 16.4039 24.6974 16.4039H22.7234V19.1059H24.6834Z"
      />
      <path d="M7 31C7.27614 31 7.5 31.2239 7.5 31.5V34.5C7.5 34.7761 7.27614 35 7 35H6C5.72386 35 5.5 34.7761 5.5 34.5V31.5C5.5 31.2239 5.72386 31 6 31H7Z" />
      <path d="M13.5 31.5C13.5 31.2239 13.2761 31 13 31H12C11.7239 31 11.5 31.2239 11.5 31.5V34.5C11.5 34.7761 11.7239 35 12 35H13C13.2761 35 13.5 34.7761 13.5 34.5V31.5Z" />
      <path d="M10 31C10.2761 31 10.5 31.2239 10.5 31.5V34.5C10.5 34.7761 10.2761 35 10 35H9C8.72386 35 8.5 34.7761 8.5 34.5V31.5C8.5 31.2239 8.72386 31 9 31H10Z" />
      <path d="M16.5 31.5C16.5 31.2239 16.2761 31 16 31H15C14.7239 31 14.5 31.2239 14.5 31.5V34.5C14.5 34.7761 14.7239 35 15 35H16C16.2761 35 16.5 34.7761 16.5 34.5V31.5Z" />
      <path d="M19 31C19.2761 31 19.5 31.2239 19.5 31.5V34.5C19.5 34.7761 19.2761 35 19 35H18C17.7239 35 17.5 34.7761 17.5 34.5V31.5C17.5 31.2239 17.7239 31 18 31H19Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0C1.79086 0 0 1.79086 0 4V36C0 38.2091 1.79086 40 4 40H36C38.2091 40 40 38.2091 40 36V4C40 1.79086 38.2091 0 36 0H4ZM38 4C38 2.89543 37.1046 2 36 2H4C2.89543 2 2 2.89543 2 4V36C2 37.1046 2.89543 38 4 38H36C37.1046 38 38 37.1046 38 36V4Z"
      />
    </g>
  </BaseSvgExtend>
);

export default StorageCard;
