import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const PiggyBank = ({ title = 'Piggy bank', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" fill="none" title={title} {...rest}>
    <path
      d="M15.5 11.0001C15.8333 8.83338 17.7 4.40005 22.5 4.00005C25.5 3.83338 31.3 5.30005 30.5 12.5M21.6662 9.5C24.0762 9.49964 26.4413 10.1525 28.5099 11.3892C30.5785 12.6259 32.2732 14.4002 33.4138 16.5233C34.5544 18.6464 35.0981 21.0389 34.9873 23.4464C34.8765 25.854 34.1152 28.1864 32.7843 30.1957C32.562 30.5226 32.4611 30.917 32.4993 31.3104L32.7115 33.2611C32.7537 33.6512 32.6986 34.0457 32.5511 34.4093C32.4036 34.773 32.1683 35.0944 31.8662 35.3448C31.5642 35.5953 31.2047 35.767 30.8201 35.8447C30.4355 35.9223 30.0376 35.9034 29.662 35.7897L27.667 35.1848C27.3007 35.0779 26.909 35.098 26.5555 35.2418C24.9984 35.8549 23.3396 36.1686 21.6662 36.1665C20.1945 36.1681 18.7328 35.9257 17.3405 35.4493C16.6153 35.2007 15.8284 35.1373 15.0953 35.3637L13.7242 35.7865C13.3513 35.9013 12.956 35.9224 12.573 35.8481C12.1901 35.7738 11.8313 35.6063 11.5285 35.3604C11.2256 35.1146 10.988 34.7979 10.8366 34.4383C10.6852 34.0788 10.6247 33.6875 10.6604 33.2991L10.7903 31.8931C10.8599 31.1236 10.6351 30.3636 10.2361 29.7018C9.98074 29.278 9.74922 28.8403 9.54258 28.3908C9.12775 27.4883 8.268 26.8328 7.27367 26.8328C6.67065 26.8328 6.09234 26.5932 5.66594 26.1668C5.23955 25.7404 5 25.1621 5 24.5591V21.1074C5 19.8518 6.01808 18.8338 7.27367 18.8338C8.268 18.8338 9.12933 18.1798 9.54258 17.2758C10.607 14.955 12.3152 12.9887 14.4643 11.6103C16.6134 10.2319 19.113 9.49947 21.6662 9.5Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
    />
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M7 22.5004C7 21.9699 7.10536 21.461 7.29289 21.0859C7.48043 20.7108 7.73478 20.5 8 20.5C8.26522 20.5 8.51957 20.7108 8.70711 21.0859C8.89464 21.461 9 21.9699 9 22.5004C9 23.0307 8.89468 23.5393 8.70722 23.9143C8.51976 24.2893 8.26551 24.5 8.0004 24.5C7.73528 24.5 7.48103 24.2893 7.29357 23.9143C7.10611 23.5393 7 23.0307 7 22.5004Z" />
      <path d="M10 22.5004C10 21.9699 10.1054 21.461 10.2929 21.0859C10.4804 20.7108 10.7348 20.5 11 20.5C11.2652 20.5 11.5196 20.7108 11.7071 21.0859C11.8946 21.461 12 21.9699 12 22.5004C12 23.0307 11.8947 23.5393 11.7072 23.9143C11.5198 24.2893 11.2655 24.5 11.0004 24.5C10.7353 24.5 10.481 24.2893 10.2936 23.9143C10.1061 23.5393 10 23.0307 10 22.5004Z" />
    </g>
  </BaseSvgExtend>
);

export default PiggyBank;
