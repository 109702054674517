import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const MobileSubscription = ({ title = 'Mobile subscription', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <g fill="currentColor">
      <path d="M18.5238 31.762C18.0242 31.762 17.6191 32.167 17.6191 32.6667C17.6191 33.1664 18.0242 33.5715 18.5238 33.5715H22.1429C22.6426 33.5715 23.0477 33.1664 23.0477 32.6667C23.0477 32.167 22.6426 31.762 22.1429 31.762H18.5238Z" />
      <path d="M30.5949 18.2274C31.0132 18.1327 31.4487 18.1456 31.8607 18.2647C32.2726 18.3839 32.6476 18.6056 32.9507 18.909L33.7102 19.6226H32.8021C32.5689 19.6226 32.3799 19.8116 32.3799 20.0448C32.3799 20.278 32.5689 20.467 32.8021 20.467H34.7761C35.0093 20.467 35.1983 20.278 35.1983 20.0448V18.0708C35.1983 17.8376 35.0093 17.6485 34.7761 17.6485C34.5429 17.6485 34.3539 17.8376 34.3539 18.0708V19.0687L33.5433 18.307C33.1401 17.9053 32.6421 17.6117 32.0953 17.4535C31.5463 17.2947 30.966 17.2776 30.4086 17.4037C29.8512 17.5299 29.3348 17.7951 28.9076 18.1748C28.4805 18.5545 28.1564 19.0362 27.9658 19.5749C27.888 19.7948 28.0031 20.036 28.223 20.1138C28.4428 20.1916 28.6841 20.0765 28.7618 19.8566C28.9049 19.4523 29.1481 19.0909 29.4686 18.806C29.7892 18.5211 30.1767 18.322 30.5949 18.2274Z" />
      <path d="M27.538 20.9386C27.3048 20.9386 27.1158 21.1276 27.1158 21.3608V23.3348C27.1158 23.568 27.3048 23.7571 27.538 23.7571C27.7712 23.7571 27.9602 23.568 27.9602 23.3348V22.3369L28.7708 23.0986C29.174 23.5003 29.672 23.7939 30.2188 23.9521C30.7678 24.1109 31.3481 24.128 31.9055 24.0019C32.4629 23.8757 32.9793 23.6105 33.4065 23.2308C33.8337 22.8511 34.1577 22.3694 34.3483 21.8307C34.4261 21.6108 34.311 21.3696 34.0912 21.2918C33.8713 21.214 33.6301 21.3291 33.5523 21.549C33.4092 21.9533 33.166 22.3147 32.8455 22.5996C32.5249 22.8845 32.1374 23.0836 31.7192 23.1782C31.3009 23.2729 30.8654 23.26 30.4534 23.1409C30.0415 23.0217 29.6665 22.8 29.3634 22.4966L28.6039 21.783H29.512C29.7452 21.783 29.9343 21.594 29.9343 21.3608C29.9343 21.1276 29.7452 20.9386 29.512 20.9386H27.538Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.3809 1C30.8759 1 32.0952 2.21936 32.0952 3.71429V13.4514C35.6657 13.8967 38.4285 16.9425 38.4284 20.6335C38.4283 24.3245 35.6655 27.3701 32.0952 27.8153V36.2857C32.0952 37.7806 30.8759 39 29.3809 39H11.2857C9.79077 39 8.57141 37.7806 8.57141 36.2857V3.71429C8.57141 2.21936 9.79077 1 11.2857 1H29.3809ZM10.3809 3.71429C10.3809 3.21873 10.7901 2.80952 11.2857 2.80952H29.3809C29.8765 2.80952 30.2857 3.21873 30.2857 3.71429V13.4515C26.7154 13.8967 23.9527 16.9423 23.9526 20.6332C23.9525 24.3242 26.7153 27.37 30.2857 27.8153V36.2857C30.2857 36.7813 29.8765 37.1905 29.3809 37.1905H11.2857C10.7901 37.1905 10.3809 36.7813 10.3809 36.2857V3.71429ZM31.1903 26.4237C34.3882 26.4237 36.9807 23.8314 36.9808 20.6335C36.9809 17.4356 34.3885 14.8431 31.1906 14.8431C27.9927 14.843 25.4003 17.4353 25.4002 20.6332C25.4001 23.8311 27.9924 26.4236 31.1903 26.4237Z"
      />
    </g>
  </BaseSvgExtend>
);

export default MobileSubscription;
