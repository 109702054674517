import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const SimExtra = ({ title = 'SimExtra', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path d="M18.2 32.6001H21.8" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1 3.8001C10.1 3.30715 10.5071 2.9001 11 2.9001H29C29.493 2.9001 29.9 3.30715 29.9 3.8001V14.0453C30.1625 14.0154 30.4295 14.0001 30.7 14.0001C31.0395 14.0001 31.3734 14.0243 31.7 14.071V3.8001C31.7 2.31304 30.4871 1.1001 29 1.1001H11C9.51296 1.1001 8.30002 2.31304 8.30002 3.8001V36.2001C8.30002 37.6872 9.51296 38.9001 11 38.9001H29C30.4871 38.9001 31.7 37.6872 31.7 36.2001V27.9292C31.3734 27.9759 31.0395 28.0001 30.7 28.0001C30.4295 28.0001 30.1625 27.9848 29.9 27.9549V36.2001C29.9 36.693 29.493 37.1001 29 37.1001H11C10.5071 37.1001 10.1 36.693 10.1 36.2001V3.8001Z"
      fill="currentColor"
    />
    <circle cx="30.7" cy="21" r="6.2" stroke="currentColor" strokeWidth="1.6" fill="none" />
    <rect x="30" y="17.5" width="1.4" height="7" rx="0.7" fill="currentColor" />
    <rect
      x="34.2"
      y="20.2998"
      width="1.4"
      height="7"
      rx="0.7"
      transform="rotate(90 34.2 20.2998)"
      fill="currentColor"
    />
  </BaseSvgExtend>
);

export default SimExtra;
