import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Phone = ({ title = 'Phone', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path
      d="M4.3 12.9C4.5 14.4 4.8 15.9 5.3 17.4C8.1 25.4 14.6 31.9 22.6 34.7C23.2 34.9 25 35.2 26.9 35.4C30 35.8 33 34.3 34.6 31.6C35.4 30.3 35 28.7 33.7 27.8L29.5 25C28.5 24.4 27.3 24.3 26.3 24.9L22.4 27.2C21.7 27.6 20.9 27.5 20.3 26.9L12.8 19.4C12.2 18.8 12.1 18 12.5 17.3L14.8 13.4C15.4 12.4 15.4 11.1 14.7 10.2L12 6.2C11.1 4.9 9.29999 4.4 7.89999 5.3C5.59999 7 3.9 9.9 4.3 12.9Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
    />
  </BaseSvgExtend>
);

export default Phone;
