import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Document = ({ title = 'Document', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      d="M31.3 13.9L21.8 2.80005M14 32H26M14 26H24M14 20H26M8 3C8 2.4 8.4 2 9 2H20C21.4 2 22.6 3.2 22.6 4.6V11.8C22.6 12.4 23 12.8 23.6 12.8H29C30.7 12.8 32 14.1 32 15.8V37C32 37.6 31.6 38 31 38H9C8.4 38 8 37.6 8 37V3Z"
    />
  </BaseSvgExtend>
);

export default Document;
