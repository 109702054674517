import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Help = ({ title = 'Help', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <g stroke="currentColor" fill="none">
      <path
        d="M31.3 8.7001C36.5 13.9001 37.4 21.7001 34 27.7001C33.9 27.9001 33.8 28.2001 33.9 28.5001L35.7 35.1001C35.8 35.5001 35.5 35.8001 35.1 35.7001L28.5 33.9001C28.2 33.8001 28 33.9001 27.7 34.0001C21.6 37.4001 13.8 36.5001 8.69999 31.3001C2.49999 25.1001 2.49999 14.9001 8.69999 8.7001C14.9 2.4001 25.1 2.4001 31.3 8.7001Z"
        strokeWidth="2"
      />
      <path
        d="M16 17.9999C16 16.1999 17.2 14.6999 18.9 14.2999H19C19.6 14.0999 20.3 14.0999 20.9 14.2999H21C22.7 14.6999 23.9 16.2999 23.9 17.9999C23.9 19.2999 23.3 20.3999 22.3 21.1999L22 21.4999C20.7 22.3999 20 23.8999 20 25.4999V25.9999"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
  </BaseSvgExtend>
);

export default Help;
