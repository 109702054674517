import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const CameraSight = ({ title = 'Camera sight', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend viewBox="0 0 40 40" fill="none" title={title} {...rest}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={0.892}
      d="M.5 12.932v-9.73C.5 2.303.932.5 2.662.5h10.27M39.42 26.986v9.73c0 .901-.432 2.703-2.162 2.703h-10.27M.5 26.987v9.73c0 .9.432 2.702 2.162 2.702h10.27M39.42 12.932v-9.73c0-.9-.432-2.702-2.162-2.702h-10.27"
    />
  </BaseSvgExtend>
);

export default CameraSight;
