import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Calendar = ({ title = 'Calendar', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.6667 3.33325C27.6667 2.78097 27.2189 2.33325 26.6667 2.33325C26.1144 2.33325 25.6667 2.78097 25.6667 3.33325V5.66675H14.3333V3.33325C14.3333 2.78097 13.8856 2.33325 13.3333 2.33325C12.7811 2.33325 12.3333 2.78097 12.3333 3.33325V5.66675H8.33333C5.9401 5.66675 4 7.60685 4 10.0001V33.3334C4 35.7266 5.9401 37.6667 8.33333 37.6667H31.6667C34.0599 37.6667 36 35.7266 36 33.3334V10.0001C36 7.60685 34.0599 5.66675 31.6667 5.66675H27.6667V3.33325ZM8.33333 7.66675C7.04467 7.66675 6 8.71142 6 10.0001V15.6667H34V10.0001C34 8.71142 32.9553 7.66675 31.6667 7.66675H27.6667V9.99992C27.6667 10.5522 27.2189 10.9999 26.6667 10.9999C26.1144 10.9999 25.6667 10.5522 25.6667 9.99992V7.66675H14.3333V9.99992C14.3333 10.5522 13.8856 10.9999 13.3333 10.9999C12.7811 10.9999 12.3333 10.5522 12.3333 9.99992V7.66675H8.33333ZM8.33333 35.6667C7.04467 35.6667 6 34.6221 6 33.3334V17.6667H34V33.3334C34 34.6221 32.9553 35.6667 31.6667 35.6667H8.33333Z"
        fill="currentColor"
      />
    </g>
  </BaseSvgExtend>
);

export default Calendar;
