import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const EuFlag = ({ title = 'EU flag', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path fill="#fff" d="M40 5H0v30h40V5Z" />
    <path fill="#F7FCFF" d="M40 5H0v30h40V5Z" />
    <path fill="#3D58DB" d="M40 8a3 3 0 0 0-3-3H3a3 3 0 0 0-3 3v24a3 3 0 0 0 3 3h34a3 3 0 0 0 3-3V8Z" />
    <path
      fill="#FFD018"
      fillRule="evenodd"
      d="m20 28.246.734 1.608h1.644l-1.19 1.28.28 1.634L20 31.996l-1.47.772.28-1.636-1.188-1.278h1.644L20 28.246Zm-5.492-1.404.736 1.608h1.642l-1.188 1.28.28 1.634-1.47-.772-1.468.772.28-1.636-1.188-1.278h1.642l.734-1.608Zm11.116 0 .734 1.608h1.644l-1.19 1.28.28 1.634-1.468-.772-1.47.772.28-1.636-1.188-1.278h1.644l.734-1.608Zm-14.766-4.164.734 1.608h1.644l-1.19 1.278.28 1.636-1.468-.772-1.47.772.28-1.636-1.188-1.28h1.642l.736-1.606Zm18.298 0 .734 1.608h1.644l-1.19 1.278.28 1.636-1.468-.772-1.47.772.28-1.636-1.188-1.28h1.644l.734-1.606ZM8.436 17.5l.734 1.608h1.642l-1.188 1.28.28 1.636-1.468-.774-1.47.774.28-1.638-1.188-1.278H7.7l.736-1.608Zm23.032-.148.736 1.61h1.642l-1.188 1.278.28 1.636-1.47-.774-1.468.774.28-1.636-1.188-1.28h1.642l.734-1.608ZM10.86 12.19l.734 1.608h1.644l-1.19 1.278.28 1.636-1.468-.772-1.47.772.28-1.636-1.188-1.28h1.642l.736-1.606Zm18.298 0 .734 1.608h1.644l-1.19 1.278.28 1.636-1.468-.772-1.47.772.28-1.636-1.188-1.28h1.644l.734-1.606Zm-14.65-4.232.736 1.608h1.642l-1.188 1.28.28 1.634-1.47-.772-1.468.772.28-1.636-1.188-1.278h1.642l.734-1.608Zm11.116 0 .734 1.608h1.644l-1.19 1.28.28 1.634-1.468-.772-1.47.772.28-1.636-1.188-1.278h1.644l.734-1.608ZM20 6.536l.734 1.608h1.644l-1.19 1.278.28 1.636L20 10.286l-1.47.772.28-1.636-1.188-1.28h1.644L20 6.536Z"
      clipRule="evenodd"
    />
  </BaseSvgExtend>
);
export default EuFlag;
