import styled, { css } from 'styled-components';

import { above } from '@/global/style/breakpoint';

import { DividerProps } from './Divider.types';

export const Component = styled.div<{
  $color: DividerProps['color'];
  $width: DividerProps['width'];
  $margin: DividerProps['margin'];
  $marginY: DividerProps['marginY'];
  $marginYSm: DividerProps['marginYSm'];
}>`
  background: ${({ $color }) => $color};
  height: 1px;
  width: ${({ $width }) => `${$width}`};

  margin: ${({ $marginY, $margin }) => {
    if ($margin) {
      return `${$margin}`;
    }
    if ($marginY) {
      return `calc(${$marginY} - 0.5px) 0`;
    }
  }};

  ${({ $marginYSm }) =>
    $marginYSm &&
    css`
      ${above.sm`
        margin: calc(${$marginYSm} - 0.5px) 0;
      `}
    `}
`;
