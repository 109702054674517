import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Esim = ({ title = 'Esim', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" fill="none" title={title} {...rest}>
    <g fill="currentColor">
      <path d="M32.1648 35.355C24.6034 35.2755 17.0494 35.1941 9.48803 35.1147C7.78245 35.095 6.07856 34.8444 5.3531 32.9968C4.94448 31.9463 5.14598 30.5337 5.15899 29.4258C5.2073 24.7728 5.25562 20.1197 5.31135 15.4647L5.37743 9.54365C5.39597 8.10058 5.28398 6.64481 6.48894 5.56713C7.6939 4.48946 9.09225 4.72656 10.5017 4.73822L30.4135 4.94872C31.5456 4.96318 32.9242 4.80037 33.9625 5.32464C35.6898 6.19661 35.7813 7.87239 35.7571 9.53151C35.708 13.9146 35.6643 18.2883 35.6151 22.6714L35.5455 29.38C35.5378 30.3594 35.6013 31.3673 35.5041 32.3388C35.3416 34.0747 33.8767 35.3094 32.1702 35.3456C30.8266 35.3719 30.8133 37.4572 32.1569 37.4308C35.2629 37.3693 37.5495 34.961 37.6096 31.8939C37.6384 30.4003 37.6411 28.898 37.6551 27.4084C37.7099 22.3644 37.7627 17.3129 37.8174 12.2688C37.8292 10.9785 37.8707 9.68026 37.8583 8.3885C37.822 5.34721 35.5056 2.9884 32.4676 2.88871C28.7236 2.76367 24.962 2.8102 21.2157 2.76524C17.1287 2.72416 13.0466 2.64204 8.96022 2.63259C6.02616 2.62424 3.53508 4.71394 3.32658 7.70163C3.25029 8.81058 3.29169 9.94361 3.27868 11.0515C3.22788 16.1104 3.16967 21.1713 3.11888 26.2302L3.06701 31.2258C3.03745 33.7842 4.2979 36.116 6.86018 36.9312C8.0936 37.3237 9.57306 37.1815 10.8485 37.1973L31.3264 37.415L32.1623 37.4214C33.5085 37.4342 33.5218 35.3489 32.1757 35.3362L32.1648 35.355Z" />
      <path d="M27.8352 29.3086C24.6159 29.2734 21.4039 29.2362 18.1866 29.2084L13.2933 29.153C11.9229 29.1388 11.1725 28.3546 11.1887 26.9917C11.2282 23.2547 11.2677 19.5178 11.3072 15.7808C11.3222 14.6803 11.2566 13.5459 11.3443 12.4497C11.5011 10.485 13.47 10.7758 14.8721 10.7894C18.6407 10.8284 22.4092 10.8674 26.1798 10.9138L25.4562 10.6151C26.9747 12.131 28.4933 13.6468 30.0118 15.1627L29.6994 14.4122C29.6753 16.9611 29.6437 19.5121 29.6196 22.0611L29.5726 26.5707C29.5617 27.8052 29.5002 29.2359 27.8406 29.2992C26.503 29.3478 26.4822 31.435 27.8273 31.3845C29.9985 31.3032 31.5652 29.7075 31.6461 27.5486C31.6835 26.5613 31.6705 25.5636 31.6742 24.5695L31.7709 15.2634C31.77 14.9935 31.8043 14.7063 31.7793 14.4349C31.7312 13.8995 31.4402 13.6438 31.0937 13.2997L27.8067 10.017C27.1667 9.37811 26.7444 8.83975 25.8001 8.81468C24.5839 8.78302 23.3629 8.79239 22.1432 8.77753C19.2275 8.7484 16.3057 8.69702 13.379 8.68668C11.629 8.67889 10.0238 9.53804 9.44057 11.2754C9.16225 12.1048 9.23951 13.0455 9.22621 13.9151L9.12298 23.493C9.09381 25.9639 8.46411 29.3664 11.0755 30.7801C12.0224 31.2892 13.0471 31.2291 14.0749 31.2397L18.3295 31.2836C21.2937 31.3156 24.2506 31.3497 27.2148 31.3817L27.7957 31.385C29.1419 31.3977 29.1552 29.3125 27.809 29.2997L27.8352 29.3086Z" />
      <path d="M13.7217 4.15312C13.7122 4.91841 13.7047 5.69111 13.6953 6.4564L13.6878 6.78417C13.6886 7.0541 13.8051 7.34069 14.0001 7.53474C14.1743 7.71052 14.4726 7.84513 14.7237 7.83346C15.2892 7.80905 15.7697 7.37838 15.773 6.7975C15.7825 6.03221 15.7899 5.25951 15.7994 4.49422L15.8069 4.16645C15.8061 3.89653 15.6896 3.60993 15.4946 3.41588C15.3204 3.2401 15.0221 3.10549 14.771 3.11716C14.2054 3.14157 13.7249 3.57224 13.7217 4.15312Z" />
      <path d="M25.4279 4.28199C25.4184 5.04727 25.411 5.81997 25.4015 6.58526L25.394 6.91303C25.3948 7.18296 25.5113 7.46955 25.7063 7.6636C25.8805 7.83938 26.1788 7.97399 26.4299 7.96232C26.9955 7.93791 27.476 7.50724 27.4792 6.92636C27.4887 6.16107 27.4962 5.38837 27.5056 4.62308L27.5131 4.29531C27.5123 4.02539 27.3958 3.73879 27.2008 3.54474C27.0266 3.36896 26.7283 3.23435 26.4772 3.24602C25.9117 3.27043 25.4312 3.7011 25.4279 4.28199Z" />
      <path d="M13.4127 33.157C13.4032 33.9223 13.3957 34.695 13.3863 35.4603L13.3787 35.7881C13.3796 36.058 13.4961 36.3446 13.6911 36.5386C13.8653 36.7144 14.1636 36.849 14.4147 36.8374C14.9802 36.813 15.4607 36.3823 15.464 35.8014C15.4734 35.0361 15.4809 34.2634 15.4904 33.4981L15.4979 33.1704C15.4971 32.9004 15.3806 32.6138 15.1856 32.4198C15.0113 32.244 14.7131 32.1094 14.4619 32.1211C13.8964 32.1455 13.4159 32.5761 13.4127 33.157Z" />
      <path d="M25.1189 33.2859C25.1094 34.0512 25.1019 34.8239 25.0925 35.5892L25.0849 35.9169C25.0858 36.1869 25.2023 36.4735 25.3973 36.6675C25.5715 36.8433 25.8698 36.9779 26.1209 36.9662C26.6864 36.9418 27.1669 36.5111 27.1702 35.9303C27.1797 35.165 27.1871 34.3923 27.1966 33.627L27.2041 33.2992C27.2033 33.0293 27.0868 32.7427 26.8918 32.5486C26.7175 32.3729 26.4193 32.2383 26.1682 32.2499C25.6026 32.2743 25.1221 32.705 25.1189 33.2859Z" />
      <path d="M36.3363 13.3246C35.571 13.3152 34.7983 13.3077 34.033 13.2982L33.7053 13.2907C33.4353 13.2915 33.1488 13.4081 32.9547 13.6031C32.7789 13.7773 32.6443 14.0756 32.656 14.3267C32.6804 14.8922 33.1111 15.3727 33.6919 15.376C34.4572 15.3854 35.2299 15.3929 35.9952 15.4024L36.323 15.4099C36.5929 15.4091 36.8795 15.2926 37.0736 15.0975C37.2493 14.9233 37.384 14.6251 37.3723 14.3739C37.3479 13.8084 36.9172 13.3279 36.3363 13.3246Z" />
      <path d="M36.2129 25.0215C35.4476 25.012 34.6749 25.0045 33.9096 24.995L33.5818 24.9875C33.3119 24.9883 33.0253 25.1049 32.8313 25.2999C32.6555 25.4741 32.5209 25.7724 32.5326 26.0235C32.557 26.589 32.9876 27.0695 33.5685 27.0728C34.3338 27.0822 35.1065 27.0897 35.8718 27.0992L36.1996 27.1067C36.4695 27.1059 36.7561 26.9894 36.9501 26.7944C37.1259 26.6201 37.2605 26.3219 37.2488 26.0707C37.2244 25.5052 36.7938 25.0247 36.2129 25.0215Z" />
      <path d="M7.33064 13.0087C6.56536 12.9993 5.79266 12.9918 5.02737 12.9823L4.69959 12.9748C4.42967 12.9756 4.14308 13.0921 3.94903 13.2871C3.77324 13.4614 3.63864 13.7596 3.65031 14.0108C3.67472 14.5763 4.10539 15.0568 4.68627 15.06C5.45156 15.0695 6.22426 15.077 6.98955 15.0865L7.31732 15.094C7.58724 15.0932 7.87383 14.9766 8.06789 14.7816C8.24367 14.6074 8.37828 14.3091 8.3666 14.058C8.3422 13.4925 7.91153 13.012 7.33064 13.0087Z" />
      <path d="M7.2092 24.713C6.44391 24.7035 5.67121 24.696 4.90592 24.6865L4.57815 24.679C4.30823 24.6798 4.02163 24.7964 3.82758 24.9914C3.6518 25.1656 3.51719 25.4639 3.52886 25.715C3.55327 26.2805 3.98394 26.761 4.56482 26.7643C5.33011 26.7737 6.10281 26.7812 6.8681 26.7907L7.19587 26.7982C7.4658 26.7974 7.75239 26.6809 7.94644 26.4859C8.12223 26.3116 8.25683 26.0134 8.24516 25.7622C8.22075 25.1967 7.79008 24.7162 7.2092 24.713Z" />
      <path d="M20.3089 27.329C16.8309 27.329 14.1284 25.0025 14.1284 20.9135C14.1284 16.7305 16.6429 13.887 20.2384 13.887C24.0924 13.887 25.8549 16.848 25.8549 21.172H17.0424C17.1599 23.71 18.5229 25.214 20.3089 25.214C22.0244 25.214 23.2229 23.898 23.6694 22.441L25.9959 23.9685C24.6094 26.248 22.5649 27.329 20.3089 27.329ZM17.1834 19.1745H22.9644C22.7764 17.271 21.8599 15.9785 20.2384 15.9785C18.6874 15.9785 17.5594 17.1535 17.1834 19.1745Z" />
    </g>
  </BaseSvgExtend>
);

export default Esim;
