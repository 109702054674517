import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Unlock = ({ title = 'Unlock', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" fill="none" title={title} {...rest}>
    <g stroke="currentColor" strokeWidth="2">
      <path d="M8 18.6C8 18.2 8.2 17.9 8.6 17.7L9.3 17.3C16 14 24 14 30.7 17.4L31.4 17.8C31.7 18 32 18.3 32 18.7V35C32 35.6 31.6 36 31 36H9C8.4 36 8 35.6 8 35V18.6Z" />
      <path
        d="M28 11.9998V9.9998C28 6.4998 25.6 3.3998 22.2 2.4998L22 2.3998C20.7 2.0998 19.4 2.0998 18.1 2.3998L17.9 2.4998C14.4 3.3998 12 6.4998 12 9.9998V15.9998"
        strokeLinecap="round"
      />
    </g>
    <path d="M20 24V28" strokeLinecap="round" />
  </BaseSvgExtend>
);

export default Unlock;
