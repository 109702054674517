import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Tool = ({ title = 'Tool', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" fill="none" title={title} {...rest}>
    <path
      d="M24.5 10.4999C24.1946 10.8114 24.0236 11.2303 24.0236 11.6666C24.0236 12.1028 24.1946 12.5217 24.5 12.8332L27.1667 15.4999C27.4782 15.8053 27.8971 15.9763 28.3333 15.9763C28.7696 15.9763 29.1885 15.8053 29.5 15.4999L35.7833 9.21655C36.6214 11.0685 36.8751 13.1319 36.5108 15.1318C36.1464 17.1316 35.1812 18.973 33.7438 20.4103C32.3064 21.8477 30.4651 22.8129 28.4652 23.1773C26.4654 23.5417 24.402 23.2879 22.55 22.4499L11.0333 33.9665C10.3703 34.6296 9.47102 35.0021 8.53334 35.0021C7.59565 35.0021 6.69638 34.6296 6.03334 33.9665C5.3703 33.3035 4.9978 32.4042 4.9978 31.4665C4.9978 30.5289 5.3703 29.6296 6.03334 28.9665L17.55 17.4499C16.7119 15.5979 16.4582 13.5345 16.8226 11.5346C17.187 9.53479 18.1521 7.69348 19.5895 6.25609C21.0269 4.8187 22.8682 3.8535 24.8681 3.48912C26.8679 3.12474 28.9314 3.37849 30.7833 4.21655L24.5167 10.4832L24.5 10.4999Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseSvgExtend>
);

export default Tool;
