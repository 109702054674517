import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Pause = ({ title = 'Pause', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <rect width={2} height={10} x={16} y={15} fill="currentColor" rx={1} />
    <rect width={2} height={10} x={23} y={15} fill="currentColor" rx={1} />
    <circle cx={20} cy={20} r={17} stroke="currentColor" fill="none" strokeWidth={2} />
  </BaseSvgExtend>
);

export default Pause;
