import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const ChevronRight = ({ title = 'Chevron right', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <g stroke="currentColor" fill="none">
      <path d="M15 30L25 20L15 10" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </BaseSvgExtend>
);

export default ChevronRight;
