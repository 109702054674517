import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const AlertTriangle = ({ title = 'Alert triangle', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <g>
      <path
        d="M20 16C20.5523 16 21 16.4477 21 17V23.6667C21 24.219 20.5523 24.6667 20 24.6667C19.4477 24.6667 19 24.219 19 23.6667V17C19 16.4477 19.4477 16 20 16Z"
        fill="currentColor"
      />
      <path
        d="M20 26.3333C19.0795 26.3333 18.3333 27.0794 18.3333 27.9999C18.3333 28.9204 19.0795 29.6666 20 29.6666H20.0167C20.9372 29.6666 21.6833 28.9204 21.6833 27.9999C21.6833 27.0794 20.9372 26.3333 20.0167 26.3333H20Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8741 4.38618C18.523 4.02081 19.2552 3.82886 20 3.82886C20.7447 3.82886 21.4769 4.02081 22.1259 4.38618C22.7749 4.75156 23.3187 5.27803 23.705 5.9148L23.7079 5.91955L37.8245 29.4862L37.8326 29.5C38.211 30.1553 38.4112 30.8982 38.4133 31.6548C38.4154 32.4115 38.2194 33.1555 37.8447 33.8128C37.47 34.4702 36.9297 35.018 36.2776 35.4017C35.6255 35.7854 34.8842 35.9917 34.1276 36L34.1166 36.0002L5.87232 36.0001C5.11571 35.9918 4.37448 35.7854 3.72235 35.4017C3.07022 35.018 2.52993 34.4702 2.15524 33.8128C1.78055 33.1555 1.58451 32.4115 1.58663 31.6548C1.58874 30.8982 1.78895 30.1553 2.16732 29.5L2.17544 29.4862L16.2921 5.91956L16.295 5.9148C16.6812 5.27803 17.2251 4.75156 17.8741 4.38618ZM20 5.82886C19.5989 5.82886 19.2047 5.93221 18.8552 6.12896C18.5065 6.32528 18.2142 6.60797 18.0063 6.94987L18.005 6.95206L3.89568 30.5065C3.69428 30.8577 3.58775 31.2554 3.58662 31.6604C3.58548 32.0679 3.69104 32.4685 3.8928 32.8224C4.09455 33.1764 4.38548 33.4714 4.73662 33.678C5.08636 33.8838 5.48369 33.9948 5.88939 34.0001H34.1106C34.5163 33.9948 34.9136 33.8838 35.2633 33.678C35.6145 33.4714 35.9054 33.1764 36.1072 32.8224C36.3089 32.4685 36.4145 32.0679 36.4133 31.6604C36.4122 31.2554 36.3057 30.8578 36.1043 30.5065L21.995 6.95206L21.9936 6.94976C21.7857 6.60792 21.4934 6.32526 21.1447 6.12896C20.7953 5.93221 20.401 5.82886 20 5.82886Z"
        fill="currentColor"
      />
    </g>
  </BaseSvgExtend>
);

export default AlertTriangle;
