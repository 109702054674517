import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const RivertyLogo = ({ title = 'Riverty logo', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="64" height="24" viewBox="0 0 64 24" title={title} {...rest}>
    <path
      fill="#527A42"
      d="m64 8.238-3.017 3.956v3.218h-1.66v-3.218l-3.016-3.956h2.057l1.836 2.437 1.822-2.437H64Zm-16.799 0v1.417h2.66v5.76h1.645V9.652h2.656V8.235l-6.96.003Zm-8.23 1.417h2.448c.727 0 1.065.274 1.065.855 0 .58-.338.853-1.066.853h-2.447V9.655Zm5.54 5.76-1.89-2.764c1.012-.274 1.54-1.002 1.54-2.142 0-1.477-.897-2.268-2.563-2.268h-4.273v7.174h1.646v-2.64h1.814l1.783 2.637 1.942.003ZM26.294 8.241v7.174h6.487V14h-4.84V12.51h4.16v-1.417h-4.16V9.652h4.843V8.235l-6.49.006Zm-5.162 0-2.117 5.304-2.131-5.306h-1.786l3.028 7.175h1.688l3.028-7.175-1.71.002ZM9.981 15.412h1.646V8.238H9.98v7.174Zm-9.981 0h1.645v-5.53H0v5.53Zm1.645-5.528h5.529V8.237H1.645v1.647Z"
    />
  </BaseSvgExtend>
);

export default RivertyLogo;
