import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const NumberMove = ({ title = 'Number move', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" fill="none" title={title} {...rest}>
    <g stroke="currentColor" fill="none">
      <path
        d="M32 15.5V35C32 35.6 31.6 36 31 36H9C8.4 36 8 35.6 8 35V14.4C8 14.1 8.1 13.9 8.3 13.7L13.7 8.3C13.9 8.1 14.1 8 14.4 8H24M26 4H35.8C35.9 4 36 4.1 36 4.2V14M36 4L26 14M15 22H25C25.6 22 26 22.4 26 23V29C26 29.6 25.6 30 25 30H15C14.4 30 14 29.6 14 29V23C14 22.4 14.4 22 15 22Z"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
  </BaseSvgExtend>
);

export default NumberMove;
