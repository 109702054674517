import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Runner = ({ title = 'Runner', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <g fill="currentColor">
      <path d="M29.7057 5.85702C29.7057 7.9872 27.9807 9.71404 25.8529 9.71404C23.725 9.71404 22 7.9872 22 5.85702C22 3.72685 23.725 2 25.8529 2C27.9807 2 29.7057 3.72685 29.7057 5.85702Z" />
      <path d="M13.8754 10.6952C14.7105 9.66534 16.0938 9.2565 17.3546 9.66692L21.023 10.8611L17.489 17.6485C16.6126 19.3317 16.748 21.2682 17.6679 22.7717L13.6631 30.6309L4.07273 32.5098C3.53075 32.6159 3.17746 33.1414 3.28365 33.6834C3.38983 34.2253 3.91527 34.5786 4.45725 34.4724L14.5253 32.5C14.8268 32.4409 15.0845 32.2465 15.224 31.9727L19.1247 24.3179C19.3467 24.4724 19.5836 24.6117 19.8349 24.7335L27.787 28.587C28.1399 28.758 28.3752 29.1037 28.4049 29.4947L29.1057 38.7147C29.1476 39.2654 29.628 39.6779 30.1786 39.636C30.7293 39.5942 31.1418 39.1138 31.1 38.5631L30.3992 29.3431C30.3155 28.2421 29.6528 27.2687 28.6592 26.7872L20.7071 22.9336C20.465 22.8163 20.2444 22.6726 20.0471 22.5077L25.2764 12.2457L25.6169 12.3565C26.3785 12.6045 27.0196 13.1288 27.4138 13.8261L30.6098 19.4805C30.8217 19.8555 31.2499 20.0526 31.6726 19.9698L36.7066 18.9836C37.2486 18.8774 37.6019 18.352 37.4957 17.81C37.3895 17.268 36.8641 16.9147 36.3221 17.0209L31.996 17.8685L29.1549 12.842C28.5146 11.7092 27.4732 10.8575 26.2359 10.4548L22.7804 9.32988C22.7693 9.32604 22.7581 9.3224 22.7469 9.31897L17.9737 7.76515C15.9256 7.09845 13.6785 7.76257 12.322 9.43549L7.82595 14.98C7.4781 15.4089 7.54386 16.0387 7.97283 16.3865C8.4018 16.7344 9.03154 16.6686 9.3794 16.2396L13.8754 10.6952Z" />
    </g>
  </BaseSvgExtend>
);

export default Runner;
