import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Returns = ({ title = 'Returns', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path
      fill="currentColor"
      d="M12.881 5.29a1 1 0 0 0-1.414-1.414l-6.522 6.522a1 1 0 0 0 .014 1.427l6.508 6.509a1 1 0 0 0 1.414-1.415l-4.814-4.814h15.846c3.796 0 6.103 1.75 7.506 3.941 1.437 2.245 1.929 4.98 1.929 6.798 0 6.783-4.8 10.74-9.435 10.74H6.957a1 1 0 1 0 0 2h16.956c5.8 0 11.435-4.915 11.435-12.74 0-2.095-.551-5.23-2.245-7.876-1.728-2.7-4.638-4.863-9.19-4.863H8.066l4.815-4.815Z"
    />
  </BaseSvgExtend>
);

export default Returns;
