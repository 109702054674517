/*
* TODO: remove this override when we've gotten rid of
*  svg {
    width: 100%;
    height: 100%;
  }
* in global_styles.js
*/
import styled, { css } from 'styled-components';

import { NewBorderRadius } from '@/global/style/variables';
import { HallonColor } from '@/global/utils/styleTypes';

export enum IconSize {
  Auto = 'auto',
  Small = 'small',
  Large = 'large',
  XLarge = 'xlarge',
}

export type IconSizeKey = Lowercase<keyof typeof IconSize>;

const bgSizeInPx: Record<IconSizeKey, string> = {
  auto: 'auto',
  small: '32px',
  large: '48px',
  xlarge: '100px',
} as const;

export const iconSizeInPx: Record<IconSizeKey, string> = {
  auto: 'auto',
  small: '24px',
  large: '40px',
  xlarge: '64px',
} as const;

export const calcSize = (size?: IconSizeKey | string): string => {
  if (!size) {
    return 'auto';
  }
  // Allowed pixel sizes
  if (iconSizeInPx[size as keyof typeof iconSizeInPx]) {
    return iconSizeInPx[size as keyof typeof iconSizeInPx];
  }
  // Allows percent as size
  if (size.includes('%')) {
    return size;
  }
  return 'auto';
};

export const IconWrapper = styled.div<Partial<{ $size: IconSizeKey | string; $bgColor: HallonColor; $color?: string }>>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: ${({ $bgColor }) => ($bgColor ? 'relative' : 'static')};
  svg {
    transition: transform 0.3s ease;
    z-index: 1;
    ${({ $size }) => `height: ${calcSize($size)}`};
    ${({ $size }) => `width: ${calcSize($size)}`};
    ${({ $color }) => `color: ${$color || 'currentColor'}`};
  }
`;

export const IconBg = styled.span<Partial<{ $size: IconSizeKey | string; $bgColor: HallonColor }>>`
  position: absolute;
  z-index: 0;

  ${({ $bgColor, $size }) =>
    $bgColor &&
    $size &&
    css`
      width: ${bgSizeInPx[$size as keyof typeof bgSizeInPx]};
      height: ${bgSizeInPx[$size as keyof typeof bgSizeInPx]};
      background-color: ${$bgColor};
      border-radius: ${NewBorderRadius.Small};
    `};
`;
