import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Video = ({ title = 'Video', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M15.9954 14.042C16.622 13.6879 17.3955 13.7183 18.0408 14.1581L25.1323 18.3531L25.1547 18.368C26.3484 19.1639 26.3484 20.9363 25.1547 21.7321L25.1323 21.7471L18.0027 25.9646L17.9961 25.9683C16.6558 26.7342 15 25.798 15 24.3001V15.8001C15 15.0588 15.3776 14.3912 15.9954 14.042ZM24.0478 20.0648L17 24.234V15.8662L24.0478 20.0353C24.0488 20.0381 24.05 20.0432 24.05 20.0501L24.0499 20.0526C24.0497 20.0582 24.0487 20.0624 24.0478 20.0648Z" />
      <path d="M3 7C2.47275 7 1.95567 7.18011 1.56789 7.56789C1.18011 7.95567 1 8.47275 1 9V31C1 31.5272 1.18011 32.0443 1.56789 32.4321C1.95567 32.8199 2.47275 33 3 33H37C37.5272 33 38.0443 32.8199 38.4321 32.4321C38.8199 32.0443 39 31.5272 39 31V9C39 8.47275 38.8199 7.95567 38.4321 7.56789C38.0443 7.18011 37.5272 7 37 7H3ZM3 31V9H37V31H3Z" />
    </g>
  </BaseSvgExtend>
);

export default Video;
