import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Unlimited = ({ title = 'Unlimited', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend fill="none" width="42" height="40" viewBox="0 0 42 40" title={title} {...rest}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.116 25.087c1.279 1.023 2.513 1.78 3.7 2.263l.008.003c1.19.446 2.434.668 3.732.668 1.811 0 3.367-.388 4.65-1.181l.002-.001c1.303-.82 2.314-1.866 3.026-3.139.707-1.267 1.066-2.561 1.066-3.881 0-1.316-.339-2.59-1.01-3.821-.64-1.242-1.616-2.271-2.918-3.09-1.287-.832-2.899-1.238-4.816-1.238-1.338 0-2.604.26-3.797.782-1.187.482-2.42 1.239-3.697 2.261l-.004.003c-1.181.973-2.525 2.208-4.03 3.705-1.544-1.532-2.925-2.787-4.144-3.762-1.279-1.023-2.514-1.763-3.704-2.21a9.987 9.987 0 0 0-3.79-.724c-1.78 0-3.334.408-4.651 1.236a8.4 8.4 0 0 0-2.971 3.083C1.058 17.276.7 18.554.7 19.873c0 1.313.32 2.604.952 3.869l.003.006c.675 1.275 1.65 2.323 2.917 3.143l.008.005c1.32.792 2.929 1.18 4.81 1.18 1.335 0 2.6-.242 3.792-.726 1.226-.483 2.461-1.241 3.706-2.266 1.216-1.008 2.578-2.278 4.085-3.808 1.542 1.564 2.923 2.835 4.143 3.81Zm-.51-.81c-1.09-.924-2.3-2.068-3.633-3.43a63.876 63.876 0 0 1-.21.214l.21-.214.21.214c1.25 1.269 2.391 2.34 3.424 3.217Zm14.74-4.458c0 1.17-.308 2.233-.926 3.194l-.004.006c-.585.963-1.393 1.74-2.432 2.329-1.034.55-2.193.829-3.482.829-1.667 0-3.252-.503-4.758-1.519-1.478-.997-3.27-2.588-5.378-4.782 2.177-2.196 4.004-3.788 5.485-4.787 1.547-1.02 3.096-1.52 4.651-1.52 2.103 0 3.755.612 4.987 1.81l.003.003c1.236 1.167 1.855 2.638 1.855 4.437ZM9.499 13.624c1.63 0 3.196.501 4.704 1.518l.006.004c1.511.962 3.32 2.515 5.426 4.673-2.178 2.232-4.025 3.86-5.541 4.894-1.51 1.018-3.04 1.518-4.595 1.518-2.102 0-3.753-.611-4.986-1.808-1.24-1.242-1.859-2.752-1.859-4.55 0-1.173.292-2.216.872-3.139.62-.93 1.448-1.67 2.488-2.226l.006-.003c1.035-.586 2.192-.881 3.479-.881Z"
    />
  </BaseSvgExtend>
);

export default Unlimited;
