import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const CustomerService = ({ title = 'Customer service', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" fill="none" title={title} {...rest}>
    <g stroke="currentColor">
      <path
        d="M4 20C4 17.8 5.8 16 8 16H11C11.6 16 12 16.4 12 17V27C12 27.6 11.6 28 11 28H8C5.8 28 4 26.2 4 24V20Z"
        strokeWidth="2"
      />
      <path
        d="M28 17C28 16.4 28.4 16 29 16H32C34.2 16 36 17.8 36 20V24C36 26.2 34.2 28 32 28H29C28.4 28 28 27.6 28 27V17Z"
        strokeWidth="2"
      />
      <path d="M32 16C32 9.4 26.6 4 20 4C13.4 4 8 9.4 8 16" strokeWidth="2" />
      <path d="M32 28V30C32 33.3 29.3 36 26 36H20" strokeWidth="2" strokeLinecap="round" />
    </g>
  </BaseSvgExtend>
);

export default CustomerService;
