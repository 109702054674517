import { HallonSvgIconProps } from '@/types/icon';
import React from 'react';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const DinersCard = ({ title = 'DinersCard', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" fill="none" viewBox="0 0 40 40" title={title} {...rest}>
    <path
      fill="#0079BE"
      d="M23.433 4C32.095 3.997 40 10.594 40 20.14c0 8.727-7.906 15.902-16.567 15.86h-7.454C7.215 36.042 0 28.87 0 20.14 0 10.595 7.214 3.996 15.98 4h7.453Z"
    />
    <path
      fill="#fff"
      d="M15.499 5C7.491 5.003 1.003 11.716 1 20.001 1.002 28.285 7.49 34.997 15.499 35c8.01-.002 14.5-6.715 14.501-14.999C29.999 11.715 23.509 5.003 15.499 5Z"
    />
    <path
      fill="#0079BE"
      d="M6 19.998c.007-4.105 2.402-7.607 5.777-8.998v17.995c-3.375-1.39-5.77-4.89-5.777-8.997ZM18.219 29V11c3.377 1.389 5.775 4.892 5.781 9-.006 4.109-2.404 7.609-5.781 9Z"
    />
  </BaseSvgExtend>
);

export default DinersCard;
