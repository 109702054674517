import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Login = ({ title = 'Login', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" fill="none" title={title} {...rest}>
    <g fill="none" stroke="currentColor">
      <path
        d="M26 3H34C35.0609 3 36.0783 3.39801 36.8284 4.10649C37.5786 4.81496 38 5.77585 38 6.77778V33.2222C38 34.2242 37.5786 35.185 36.8284 35.8935C36.0783 36.602 35.0609 37 34 37H26M16 30L26 20M26 20L16 10M26 20H2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </BaseSvgExtend>
);

export default Login;
