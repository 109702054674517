import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Package = ({ title = 'Package', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path
      d="M20 37.5L2 30.118V10.5L20 17.8567M20 37.5V17.8567M20 37.5L38 30.118V10.5L20 17.8567M38 10.0129L20 17.5L2 10.0129L20 2.5L38 10.0129Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseSvgExtend>
);

export default Package;
