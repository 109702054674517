import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Logout = ({ title = 'Logout', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path
      d="M14 3H6C4.93913 3 3.92172 3.39801 3.17157 4.10649C2.42143 4.81496 2 5.77585 2 6.77778V33.2222C2 34.2242 2.42143 35.185 3.17157 35.8935C3.92172 36.602 4.93913 37 6 37H14M24 30L14 20M14 20L24 10M14 20H38"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseSvgExtend>
);

export default Logout;
