import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const SimCard = ({ title = 'Simcard', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M9 38H31C31.6 38 32 37.6 32 37V10.4C32 10.1 31.9 9.9 31.7 9.7L24.3 2.3C24.1 2.1 23.9 2 23.6 2H9C8.4 2 8 2.4 8 3V37C8 37.6 8.4 38 9 38Z" />
      <path d="M15 24H25C25.6 24 26 24.4 26 25V31C26 31.6 25.6 32 25 32H15C14.4 32 14 31.6 14 31V25C14 24.4 14.4 24 15 24Z" />
    </g>
  </BaseSvgExtend>
);

export default SimCard;
