import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const MyPages = ({ title = 'My pages', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.0641 32.7752C37.1309 29.401 39 24.9188 39 20C39 9.50659 30.4934 1 20 1C9.50659 1 1 9.50659 1 20C1 24.7957 2.77677 29.1765 5.70795 32.5199C5.85043 32.6824 5.99564 32.8424 6.14351 33C6.43769 33.3134 6.74239 33.6169 7.05705 33.9098C7.10597 33.9554 7.15512 34.0007 7.20452 34.0457C10.581 37.1234 15.0713 39 20 39C24.8833 39 29.3362 37.1578 32.7019 34.1304C33.1784 33.7018 33.6331 33.2494 34.0641 32.7752ZM31.3717 32.4869C28.3706 35.2215 24.38 36.8889 20 36.8889C15.5832 36.8889 11.5624 35.1934 8.5529 32.4178C10.4331 30.416 12.7932 28.8699 15.3817 27.965C15.8628 27.8015 16.1642 27.447 16.336 27.1379C16.5106 26.8235 16.5997 26.4762 16.6305 26.1579C16.6613 25.8392 16.6397 25.4882 16.5374 25.1564C16.4426 24.849 16.2431 24.4526 15.8398 24.1907C13.9068 22.7979 12.6111 20.3796 12.6111 17.8889C12.6111 13.7219 15.8329 10.5 20 10.5C24.1496 10.5 27.2833 13.7043 27.2833 17.8889C27.2833 20.3856 25.9816 22.8054 23.9442 24.1945L23.9109 24.2172L23.8795 24.2424C23.216 24.7732 23.1021 25.6355 23.1587 26.2009C23.2128 26.7425 23.5009 27.663 24.4162 27.9681C27.0611 28.8497 29.4705 30.4219 31.3717 32.4869ZM32.8422 30.9692C30.7503 28.7279 28.1291 27.0152 25.2641 26.0264C25.2622 26.0153 25.2606 26.0034 25.2593 25.9908C25.2558 25.9557 25.2556 25.9205 25.2584 25.8879C25.2597 25.8731 25.2615 25.8599 25.2635 25.8485C27.7954 24.0585 29.3944 21.0386 29.3944 17.8889C29.3944 12.5735 25.3503 8.38889 20 8.38889C14.667 8.38889 10.5 12.5559 10.5 17.8889C10.5 21.0426 12.1027 24.062 14.531 25.8491C14.5319 25.8633 14.5324 25.8786 14.5323 25.8948C14.5322 25.9143 14.5311 25.9344 14.5292 25.9546C14.5272 25.9748 14.5244 25.9944 14.5209 26.013C14.5197 26.0195 14.5184 26.0257 14.5171 26.0318C11.7235 27.0385 9.15897 28.7188 7.092 30.8918C4.60827 27.9513 3.11111 24.1505 3.11111 20C3.11111 10.6725 10.6725 3.11111 20 3.11111C29.3275 3.11111 36.8889 10.6725 36.8889 20C36.8889 24.187 35.3653 28.0181 32.8422 30.9692Z"
      fill="currentColor"
    />
  </BaseSvgExtend>
);

export default MyPages;
