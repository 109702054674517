import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';
import { Color } from '@/global/style/variables';

export type MultiColorCheckmarkProps = {
  boxColor?: string;
  checkmarkColor?: string;
} & HallonSvgIconProps;

const MultiColorCheckmark = (props: MultiColorCheckmarkProps) => {
  const { title = 'Multicolor checkmark', boxColor = Color.MineShaft, checkmarkColor = Color.Hallon1, ...rest } = props;

  return (
    <BaseSvgExtend fill="none" width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
      <path
        stroke={boxColor}
        strokeLinecap="round"
        strokeWidth={3.3}
        d="M20.958 4.815H8.811a6.074 6.074 0 0 0-6.074 6.074V29.11a6.074 6.074 0 0 0 6.074 6.074h18.221a6.074 6.074 0 0 0 6.074-6.074v-6.073"
      />
      <path
        stroke={checkmarkColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3.3}
        d="m13.046 17.227 6.076 6.074 18.22-18.22"
      />
    </BaseSvgExtend>
  );
};

export default MultiColorCheckmark;
