import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const ChevronLeft = ({ title = 'Chevron left', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" fill="none" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <g stroke="currentColor">
      <path d="M25 30L15 20L25 10" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </BaseSvgExtend>
);

export default ChevronLeft;
