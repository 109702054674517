import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Download = ({ title = 'Download', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path
      fill="currentColor"
      d="M39 25.9998C39 25.4475 38.5523 24.9998 38 24.9998C37.4477 24.9998 37 25.4475 37 25.9998H39ZM3 25.9998C3 25.4475 2.55228 24.9998 2 24.9998C1.44772 24.9998 1 25.4475 1 25.9998H3ZM21 5C21 4.44772 20.5523 4 20 4C19.4477 4 19 4.44772 19 5H21ZM19 26C19 26.5523 19.4477 27 20 27C20.5523 27 21 26.5523 21 26H19ZM29.1071 19.5071C29.4977 19.1166 29.4977 18.4835 29.1071 18.0929C28.7166 17.7024 28.0835 17.7024 27.6929 18.0929L29.1071 19.5071ZM20.8496 26.3504L20.1425 25.6433L20.1425 25.6433L20.8496 26.3504ZM19.1504 26.3504L19.8575 25.6433L19.8571 25.6429L19.1504 26.3504ZM12.3259 18.1213C11.9351 17.731 11.302 17.7314 10.9117 18.1222C10.5214 18.513 10.5218 19.1461 10.9126 19.5364L12.3259 18.1213ZM37 25.9998V35.5998H39V25.9998H37ZM37 35.5998C37 36.5398 36.3888 36.9998 35.9744 36.9998V38.9998C37.7968 38.9998 39 37.3142 39 35.5998H37ZM35.9744 36.9998H4.4V38.9998H35.9744V36.9998ZM4.4 36.9998C3.62683 36.9998 3 36.3741 3 35.5998H1C1 37.48 2.52357 38.9998 4.4 38.9998V36.9998ZM3 35.5998V25.9998H1V35.5998H3ZM19 5V26H21V5H19ZM27.6929 18.0929L20.1425 25.6433L21.5567 27.0575L29.1071 19.5071L27.6929 18.0929ZM20.1425 25.6433C20.0627 25.7232 19.9374 25.7232 19.8575 25.6433L18.4433 27.0575C19.3043 27.9185 20.6958 27.9185 21.5567 27.0575L20.1425 25.6433ZM19.8571 25.6429L12.3259 18.1213L10.9126 19.5364L18.4438 27.058L19.8571 25.6429Z"
    />
  </BaseSvgExtend>
);

export default Download;
