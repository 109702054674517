import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';
import { Color } from '@/global/style/variables';

type CheckProps = {
  circleColor?: string;
  checkmarkColor?: string;
} & HallonSvgIconProps;

const Check = ({
  title = 'Check',
  fill = Color.Forest,
  circleColor = Color.Forest,
  checkmarkColor = Color.White,
  ...rest
}: CheckProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" fill={fill} title={title} {...rest}>
    <g>
      <path
        d="M20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z"
        strokeWidth="2"
        stroke={circleColor}
      />
      <path
        d="M12 20L17.2 25.4C17.6 25.8 18.2 25.8 18.6 25.4L28 16"
        stroke={checkmarkColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
  </BaseSvgExtend>
);

export default Check;
