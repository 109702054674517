import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Music = ({ title = 'Music', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path
      d="M14 32C14 35.3137 11.3137 38 8 38C4.68629 38 2 35.3137 2 32C2 28.6863 4.68629 26 8 26C11.3137 26 14 28.6863 14 32ZM14 32V6.80006C14 6.30006 14.3 5.90006 14.8 5.80006L34.8 2.20006C35.4 2.10006 36 2.60006 36 3.20006V28M36 28C36 31.3137 33.3137 34 30 34C26.6863 34 24 31.3137 24 28C24 24.6863 26.6863 22 30 22C33.3137 22 36 24.6863 36 28ZM14 14L36 10"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </BaseSvgExtend>
);

export default Music;
