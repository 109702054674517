import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Wifi = ({ title = 'Wifi', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" fill="none" title={title} {...rest}>
    <g fill="currentColor">
      <path d="M11.6179 6.78625C13.9319 4.96769 16.6867 4 19.5 4C22.3133 4 25.0681 4.96769 27.3821 6.78625C27.8163 7.12751 28.445 7.05214 28.7862 6.61791C29.1275 6.18367 29.0521 5.55501 28.6179 5.21375C25.9655 3.12924 22.779 2 19.5 2C16.221 2 13.0345 3.12924 10.3821 5.21375C9.94786 5.55501 9.87248 6.18367 10.2137 6.61791C10.555 7.05214 11.1837 7.12751 11.6179 6.78625Z" />
      <path d="M20 10C18.068 10 16.1793 10.6278 14.596 11.803C14.1525 12.1321 13.5262 12.0395 13.197 11.596C12.8679 11.1525 12.9605 10.5262 13.404 10.197C15.3264 8.77015 17.6316 8 20 8C22.3684 8 24.6736 8.77015 26.596 10.197C27.0395 10.5262 27.1321 11.1525 26.803 11.596C26.4738 12.0395 25.8475 12.1321 25.404 11.803C23.8207 10.6278 21.9321 10 20 10Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75097 10.3397C7.38628 9.92493 6.75441 9.88435 6.33966 10.249C5.92491 10.6137 5.88434 11.2456 6.24903 11.6603L17.5391 24.5H6C4.34315 24.5 3 25.8432 3 27.5V34C3 35.6569 4.34315 37 6 37H33.5C35.1569 37 36.5 35.6569 36.5 34V27.5C36.5 25.8432 35.1569 24.5 33.5 24.5H22.0184L33.7382 11.6746C34.1108 11.2669 34.0823 10.6344 33.6746 10.2618C33.2669 9.88925 32.6343 9.91774 32.2618 10.3254L19.7643 24.0019L7.75097 10.3397ZM5 27.5C5 26.9477 5.44772 26.5 6 26.5H33.5C34.0523 26.5 34.5 26.9477 34.5 27.5V34C34.5 34.5523 34.0523 35 33.5 35H6C5.44772 35 5 34.5523 5 34V27.5Z"
      />
      <path d="M21.4145 17.4145C22.1965 16.6325 22.1965 15.3685 21.4145 14.5865C20.6325 13.8045 19.3685 13.8045 18.5865 14.5865C17.8045 15.3685 17.8045 16.6325 18.5865 17.4145C19.3685 18.1965 20.6325 18.1965 21.4145 17.4145Z" />
    </g>
  </BaseSvgExtend>
);

export default Wifi;
