import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Lock = ({ title = 'Lock', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" fill="none" title={title} {...rest}>
    <g fill="currentColor">
      <path d="M21 24C21 23.4477 20.5523 23 20 23C19.4477 23 19 23.4477 19 24V28C19 28.5523 19.4477 29 20 29C20.5523 29 21 28.5523 21 28V24Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8751 3.42541C19.3231 3.09127 20.7769 3.09127 22.2249 3.42541L22.3408 3.45217L22.5581 3.56081C26.3386 4.61043 29 8.07727 29 11.9998V15.5436C29.7294 15.8292 30.4478 16.1507 31.1525 16.5083L31.1746 16.5195L31.926 16.9489L31.9547 16.968C32.3151 17.2083 33 17.7692 33 18.7V35C33 35.5273 32.8199 36.0444 32.4321 36.4321C32.0443 36.8199 31.5272 37 31 37H9C8.47275 37 7.95567 36.8199 7.56789 36.4321C7.18011 36.0444 7 35.5273 7 35V18.6C7 18.2251 7.09662 17.8523 7.31795 17.5203C7.52881 17.2041 7.81688 16.9783 8.12511 16.8197L8.83056 16.4165L8.85815 16.403C9.55936 16.0576 10.2742 15.7469 11 15.471V11.9998C11 8.07109 13.6681 4.6058 17.5451 3.5592L17.7592 3.45217L17.8751 3.42541ZM21.9441 5.46651C24.9094 6.25144 27 8.95759 27 11.9998V14.8583C22.4375 13.5104 17.5601 13.5016 13 14.8062V11.9998C13 8.96416 15.0832 6.25664 18.149 5.4683L18.2521 5.44181L18.4377 5.34901C19.5141 5.11674 20.5859 5.11674 21.6623 5.34901L21.8447 5.44021L21.9441 5.46651ZM9.76915 18.1837L9.07204 18.5821L9.04721 18.5945C9.02675 18.6047 9.01131 18.6137 9 18.621V35H31V18.7598C30.9899 18.7484 30.9756 18.7337 30.9559 18.7162C30.9319 18.6948 30.903 18.6721 30.8697 18.6488L30.2256 18.2807C23.8232 15.0406 16.1774 15.0381 9.76915 18.1837Z"
      />
    </g>
  </BaseSvgExtend>
);

export default Lock;
