import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Heart = ({ title = 'Heart', fill = 'none', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" fill={fill} title={title} {...rest}>
    <path
      d="M19.252 35.5C19.252 35.5 1.99276 27.7527 2 14.1692C2 9.32381 5.95604 4.47838 11.7833 4.50007C16.2696 4.50007 19.5398 8.40535 19.5398 8.40535C19.5398 8.40535 23.4343 4.53804 27.3216 4.5507C33.1452 4.56878 37.018 8.46501 36.9999 14.2886C36.9565 27.8666 19.252 35.5 19.252 35.5Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseSvgExtend>
);

export default Heart;
