import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const VisaCard = ({ title = 'Visa card', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend height="40" width="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path
      fill="#1434CB"
      d="M26.053 13.5c-2.84 0-5.378 1.472-5.378 4.192 0 3.12 4.502 3.335 4.502 4.902 0 .66-.756 1.25-2.048 1.25-1.833 0-3.203-.824-3.203-.824l-.586 2.745s1.578.697 3.674.697c3.105 0 5.548-1.545 5.548-4.311 0-3.297-4.52-3.506-4.52-4.96 0-.517.62-1.084 1.908-1.084 1.453 0 2.639.6 2.639.6l.573-2.65s-1.29-.557-3.108-.557ZM.07 13.7 0 14.1s1.195.219 2.271.655c1.386.5 1.484.792 1.718 1.696l2.543 9.804H9.94L15.192 13.7h-3.4l-3.375 8.536-1.377-7.235c-.127-.829-.766-1.3-1.55-1.3H.07Zm16.49 0-2.667 12.555h3.243L19.794 13.7H16.56Zm18.09 0c-.783 0-1.197.419-1.501 1.15l-4.752 11.405h3.401l.659-1.9h4.143l.4 1.9H40L37.382 13.7h-2.734Zm.442 3.392 1.008 4.711h-2.701l1.693-4.71Z"
    />
  </BaseSvgExtend>
);

export default VisaCard;
