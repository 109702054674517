import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Instalments = ({ title = 'Instalments', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path
      stroke="currentColor"
      fill="none"
      strokeWidth={1.565}
      d="M35.217 16c0 .13-.062.332-.344.6-.284.267-.736.545-1.36.795-1.244.497-3.016.822-5.013.822-1.997 0-3.769-.325-5.013-.822-.624-.25-1.076-.528-1.36-.796-.282-.267-.344-.47-.344-.599 0-.13.062-.332.344-.6.284-.267.736-.545 1.36-.795 1.244-.497 3.016-.822 5.013-.822 1.997 0 3.769.325 5.013.822.624.25 1.076.528 1.36.796.282.267.344.47.344.599Zm-17 0c0 .13-.062.332-.344.6-.284.267-.736.545-1.36.795-1.244.497-3.016.822-5.013.822-1.997 0-3.769-.325-5.013-.822-.624-.25-1.076-.528-1.36-.796-.282-.267-.344-.47-.344-.599 0-.13.062-.332.344-.6.284-.267.736-.545 1.36-.795 1.244-.497 3.016-.822 5.013-.822 1.997 0 3.769.325 5.013.822.624.25 1.076.528 1.36.796.282.267.344.47.344.599Z"
    />
    <path
      fill="currentColor"
      d="M22.071 19c-.68.458-1.071.995-1.071 1.569 0 1.68 3.358 3.043 7.5 3.043 4.07 0 7.384-1.316 7.497-2.956h-1.704c-.196.176-.543.397-1.078.614-1.128.458-2.795.777-4.715.777s-3.587-.319-4.715-.777c-.535-.217-.882-.438-1.078-.614h-.635V19ZM5.071 19C4.391 19.458 4 19.995 4 20.57c0 1.68 3.358 3.043 7.5 3.043 4.07 0 7.384-1.316 7.497-2.956h-1.704c-.196.176-.543.397-1.078.614-1.128.458-2.794.777-4.715.777-1.92 0-3.587-.319-4.715-.777-.535-.217-.882-.438-1.078-.614h-.635V19Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.071 23.875c-.68.399-1.071.866-1.071 1.366 0 1.464 3.358 2.65 7.5 2.65 4.142 0 7.5-1.186 7.5-2.65 0-.5-.391-.967-1.071-1.366v1.366h-.765a4.912 4.912 0 0 1-.882.398c-1.15.407-2.84.687-4.782.687-1.942 0-3.632-.28-4.782-.687a4.912 4.912 0 0 1-.882-.398h-.765v-1.366ZM5.071 23.875C4.391 24.274 4 24.74 4 25.24c0 1.464 3.358 2.65 7.5 2.65 4.142 0 7.5-1.186 7.5-2.65 0-.5-.391-.967-1.071-1.366v1.366h-.765a4.912 4.912 0 0 1-.882.398c-1.15.407-2.84.687-4.782.687-1.942 0-3.632-.28-4.782-.687a4.912 4.912 0 0 1-.882-.398h-.765v-1.366Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M34.26 16.91h.436l.434-.758H36v9.097h-.87v.219h-.87V16.91ZM17.26 16.91h.436l.434-.758H19v9.097h-.87v.219h-.87V16.91ZM22.522 16.152l.217.379v8.937h-.87v-.227H21v-9.089h1.522ZM5.522 16.152l.217.379v8.937h-.87v-.227H4v-9.089h1.522Z"
    />
  </BaseSvgExtend>
);

export default Instalments;
