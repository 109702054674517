import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const XOctagon = ({ title = 'Octagon with x', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <g stroke="currentColor" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M12.548 2H27.452L38 12.548V27.452L27.452 38H12.548L2 27.452V12.548L12.548 2Z" />
      <path d="M25 15L15 25M15 15L25 25" />
    </g>
  </BaseSvgExtend>
);

export default XOctagon;
