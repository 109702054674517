import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Paperplane = ({ title = 'Paperplane', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path
      d="M9.0495 23.6L2.65626 20C2.45867 19.8993 2.29285 19.7463 2.17706 19.5579C2.06127 19.3694 2 19.1529 2 18.932C2 18.7111 2.06127 18.4946 2.17706 18.3061C2.29285 18.1177 2.45867 17.9647 2.65626 17.864L38 2M38 2L13.8746 26V38L18.6997 28.4M38 2L28.975 32.156C28.9208 32.3213 28.8329 32.4731 28.7172 32.6013C28.6015 32.7296 28.4605 32.8314 28.3035 32.9C28.1465 32.9687 27.977 33.0027 27.8062 32.9998C27.6354 32.9969 27.4672 32.9572 27.3125 32.8832L19 28.6653"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseSvgExtend>
);

export default Paperplane;
