import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Switch = ({ title = 'Switch', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" fill="none" title={title} {...rest}>
    <path
      d="M22.5 25H2.5M9.5 34L2.81248 26.351C2.39584 25.8744 2.39584 25.1061 2.81248 24.6296L9.47449 17M17.5 14H36.5M29.5 6L37.1429 13.1991C37.619 13.6476 37.619 14.3707 37.1429 14.8192L29.5292 22"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseSvgExtend>
);

export default Switch;
