import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Aeroplane = ({ title = 'Aeroplane', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <g>
      <path
        stroke="currentColor"
        fill="none"
        d="M2 25.7V22.1C2 22 2 22 2.1 21.9L15.9 13C16 13 16 13 16 12.9V6C16 5 17 2 20 2C23 2 24 5 24 6V12.9C24 13 24 13 24.1 13.1L37.9 22C38 22 38 22 38 22.1V25.7C38 25.8 37.9 25.9 37.7 25.9L24.2 22C24.1 22 23.9 22.1 23.9 22.2V29.8C23.9 29.9 23.9 29.9 24 30L28.8 33.9C28.8 33.9 28.9 34 28.9 34.1V37.7C28.9 37.8 28.8 37.9 28.6 37.9L20.1 35H20L11.3 37.9C11.2 37.9 11 37.8 11 37.7V34.1C11 34 11 34 11.1 33.9L15.9 30C15.9 30 16 29.9 16 29.8V22.2C16 22.1 15.9 22 15.7 22L2.3 25.9C2.1 26 2 25.9 2 25.7Z"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
  </BaseSvgExtend>
);

export default Aeroplane;
