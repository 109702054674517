import { CSSProperties, ElementType } from 'react';
import classNames from 'classnames';

import { Color, getColorClass } from '@/global/style/variables';
import { HallonColor } from '@/global/utils/styleTypes';

import { getBaseStyle } from '@/newComponents/Typography/Typography.utils';
import styles from '@/newComponents/Typography/Typography.module.css';
import { EitherTextOrChildren, TypographyType, TypographyTypeAndWeight } from './Typography.types';

export type TypographyProps = {
  className?: string;
  id?: string;
  color?: HallonColor;
  align?: 'left' | 'center' | 'right';
  underlined?: boolean;
  strikethrough?: boolean;
  ['data-testid']?: string;
  elementAs?: ElementType;
  htmlFor?: string;
  maxWidth?: string;
} & TypographyTypeAndWeight &
  EitherTextOrChildren;

const Typography = (props: TypographyProps) => {
  const {
    id,
    type = TypographyType.Body,
    fontWeight,
    text,
    color = Color.MineShaft,
    align = 'left',
    underlined = false,
    strikethrough = false,
    className = '',
    'data-testid': dataTestId,
    elementAs,
    htmlFor = null,
    children,
    maxWidth,
  } = props;

  const baseStyles = getBaseStyle(type, elementAs);

  const inlineStyle: CSSProperties = {
    fontWeight: fontWeight ?? baseStyles.defaultWeight,
    maxWidth: maxWidth ?? 'none',
  };

  const textDecoration = [];
  if (underlined) {
    textDecoration.push('underline');
  }
  if (strikethrough) {
    textDecoration.push('line-through');
  }

  if (textDecoration.length > 0) {
    inlineStyle.textDecoration = textDecoration.join(' ');
  }

  const alignClass = styles[`align-${align}`];

  const typographyClasses = [styles.typography, baseStyles.className, alignClass, getColorClass(color), className];
  const Element = baseStyles.as || 'span';

  return (
    <Element
      className={classNames(typographyClasses)}
      id={id}
      style={inlineStyle}
      as={baseStyles.as}
      data-testid={dataTestId}
      htmlFor={htmlFor}
    >
      {text ?? children}
    </Element>
  );
};

export default Typography;
