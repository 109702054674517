import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const BankId = ({ title = 'BankId logo', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <defs />
    <path
      fill="currentColor"
      d="m15.288 10.763 1.14-7.241h8.554c7.746 0 13.158 3.4 11.813 11.92-1.087 6.865-6.617 11.211-13.384 11.211h-1.603l1.087-6.928c2.743-.022 5.045-1.313 5.519-4.315.505-3.227-1.108-4.626-4.12-4.647h-2.217c-.506 0-1.162-.29-1.334-.743-.054-.15-.162-.656.667-1.237.312-.226.86-.678.979-1.226.097-.463-.032-.904-.366-1.216-.377-.355-1-.549-1.818-.549-1.366 0-2.4.828-2.518 1.592-.01.054-.01.108-.01.172 0 .313.118.754.677 1.173.205.15.41.377.41.678 0 .14-.044.29-.151.463-.323.527-1.076.893-1.872.893h-1.453ZM15.147 11.343l-1.42 8.951c-.315 2-.58 3.71-.758 4.858v.002l-.231 1.477h-8.37c.068-.452.496-3.17.972-6.194.633-4.026 1.353-8.596 1.426-9.094h1.55c1-.01 1.936-.473 2.367-1.183.42-.689.258-1.42-.42-1.926-.226-.172-.484-.441-.43-.775.075-.452.839-1.076 1.925-1.076.646 0 1.152.14 1.41.388a.668.668 0 0 1 .194.656c-.054.247-.323.57-.743.86-1.173.829-.968 1.679-.882 1.926.269.732 1.183 1.13 1.893 1.13h1.517Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.058 31.151c.108-.678-.247-1.42-1.71-1.42H3.915l-.914 5.874h3.905c1.087 0 1.915-.71 2.066-1.667.118-.764-.204-1.248-1.022-1.56.646-.27 1.022-.678 1.108-1.227Zm-1.829.237c-.064.42-.376.774-1.043.774h-.592l.237-1.538h.591c.667 0 .872.312.807.764Zm-.226 2.453c-.097.57-.495.85-1.183.85v.01h-.613l.258-1.646h.667c.667 0 .947.29.871.786ZM13.781 35.648c-.893.065-1.323-.032-1.538-.42-.474.291-1 .442-1.56.442-1.012 0-1.367-.527-1.27-1.108.043-.28.205-.549.463-.775.307-.266.862-.405 1.388-.538.432-.108.844-.212 1.086-.377.043-.408-.118-.559-.624-.559-.591 0-1.086.194-1.936.775l.204-1.334c.732-.527 1.442-.775 2.26-.775 1.043 0 1.968.43 1.796 1.57l-.204 1.292c-.075.452-.054.592.452.602l-.517 1.205Zm-2.133-1.806c-.403.117-.805.235-.868.644-.043.29.14.506.43.506.28 0 .624-.118.904-.312-.022-.108-.011-.215.021-.42l.097-.645c-.162.103-.373.165-.584.227Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="m17.138 31.646.097-.592h-1.786l-.7 4.53h1.786l.41-2.658c.247-.333.505-.484.785-.484.344 0 .484.215.409.667l-.388 2.485h1.786L20 32.593c.161-1-.302-1.614-1.302-1.614-.56 0-.99.183-1.56.667ZM20.484 35.583l.915-5.852h1.786l-.452 2.883 1.71-1.56h2.206l-2.195 1.936 1.765 2.604H23.97l-1.355-2.109h-.022l-.323 2.098h-1.786ZM28.865 35.593l.904-5.863h-2.055l-.904 5.863h2.055Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m29.855 35.593.915-5.863h2.937c2.27 0 2.926 1.646 2.71 3.012-.204 1.335-1.258 2.852-3.248 2.852h-3.314Zm2.819-1.398c1 0 1.549-.495 1.71-1.539.119-.774-.118-1.538-1.226-1.538h-.549l-.473 3.077h.538Z"
      clipRule="evenodd"
    />
  </BaseSvgExtend>
);

export default BankId;
