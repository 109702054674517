import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const WifiOff = ({ title = 'Wifi off', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path
      d="M2 2L38 38M20 33.0908H20.0174M14.3219 26.7259C15.9831 25.5457 17.9705 24.9116 20.0083 24.9116C22.0461 24.9116 24.0334 25.5457 25.6946 26.7259M27.7235 18.4619C29.0639 19.1161 30.3172 19.9351 31.4544 20.9001M8.54553 20.9002C10.9708 18.8724 13.8897 17.5231 17.0055 16.9893M2.68738 15.0907C4.95728 13.0842 7.55997 11.4894 10.3783 10.3779M17.8889 8.62756C21.3947 8.34507 24.9214 8.77183 28.2586 9.88235C31.5958 10.9929 34.6751 12.7644 37.3126 15.0912"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseSvgExtend>
);

export default WifiOff;
