import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Delivery = ({ title = 'Delivery', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 34C13.7475 34 15.9774 31.7841 15.9998 29.0418H26.0002C26.0226 31.7841 28.2525 34 31 34C33.7475 34 35.9774 31.7841 35.9998 29.0418H36.5C38.1569 29.0418 39.5 27.6987 39.5 26.0418V22.0901C39.5 21.3588 39.2329 20.6527 38.7489 20.1045L34.3467 15.1187C33.7772 14.4737 32.9583 14.1043 32.0979 14.1043H30C29.4477 14.1043 29 13.6566 29 13.1043V8.9585C29 7.30164 27.6569 5.9585 26 5.9585H4C2.34314 5.9585 1 7.30164 1 8.9585V26.0418C1 27.6987 2.34315 29.0418 4 29.0418H6.00017C6.02255 31.7841 8.25248 34 11 34ZM4 7.9585C3.44772 7.9585 3 8.40621 3 8.9585V26.0418C3 26.5941 3.44772 27.0418 4 27.0418H6.39805C7.15995 25.2536 8.93358 24 11 24C13.0664 24 14.8401 25.2536 15.6019 27.0418H26.3981C26.5569 26.669 26.7597 26.3194 27 25.9996V8.9585C27 8.40621 26.5523 7.9585 26 7.9585H4ZM37.5 26.0418C37.5 26.5941 37.0523 27.0418 36.5 27.0418H35.6019C34.8401 25.2536 33.0664 24 31 24C30.2889 24 29.6125 24.1485 29 24.4161V15.9336C29.3128 16.0442 29.6494 16.1043 30 16.1043H32.0979C32.3847 16.1043 32.6577 16.2275 32.8475 16.4425L37.2496 21.4282C37.411 21.6109 37.5 21.8463 37.5 22.0901V26.0418ZM31 32C32.6569 32 34 30.6569 34 29C34 27.3431 32.6569 26 31 26C29.3431 26 28 27.3431 28 29C28 30.6569 29.3431 32 31 32ZM14 29C14 30.6569 12.6569 32 11 32C9.34315 32 8 30.6569 8 29C8 27.3431 9.34315 26 11 26C12.6569 26 14 27.3431 14 29Z"
      fill="currentColor"
    />
  </BaseSvgExtend>
);

export default Delivery;
