import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Recycled = ({ title = 'Recycled', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path
      fill="currentColor"
      d="M15.807 6.01A2 2 0 0 1 17.544 5h3.796a2 2 0 0 1 1.705.953l2.66 4.334-1.58.684c-.805.348-.805 1.488 0 1.836l4.001 1.729a1 1 0 0 0 1.365-.669l1-3.89a1 1 0 0 0-1.364-1.168l-1.566.677-2.812-4.58a4 4 0 0 0-3.409-1.906h-3.796a4 4 0 0 0-3.474 2.018l-5.524 9.685a1 1 0 0 0 1.737.99l5.524-9.684ZM25.225 30.803 22.07 33.81a1 1 0 0 0 .082 1.518l3.19 2.44a1 1 0 0 0 1.608-.8l-.012-1.863 4.278-.005a4 4 0 0 0 3.407-1.91l1.983-3.236a4 4 0 0 0 .094-4.017l-5.372-9.77a1 1 0 0 0-1.753.964l5.372 9.77a2 2 0 0 1-.047 2.008l-1.983 3.236a2 2 0 0 1-1.703.956l-4.289.004-.01-1.586c-.005-.876-1.056-1.322-1.69-.717ZM8.969 17.5l1.248 4.177c.25.84-.623 1.573-1.407 1.18l-1.843-.924-3.11 5.743a2 2 0 0 0 .026 1.952l1.897 3.288a2 2 0 0 0 1.743 1l11.149-.059a1 1 0 1 1 .01 2l-11.148.059a4 4 0 0 1-3.486-2l-1.897-3.288a4 4 0 0 1-.053-3.905l3.08-5.687-1.239-.622a1 1 0 0 1 .017-1.796l3.623-1.734a1 1 0 0 1 1.39.616Z"
    />
  </BaseSvgExtend>
);

export default Recycled;
