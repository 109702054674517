import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Mastercard = ({ title = 'Mastercard', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path fill="#FF5F00" d="M15 10.67h10v19.66H15V10.67Z" />
    <path
      fill="#EB001B"
      d="M15.279 20.5a12.597 12.597 0 0 1 1.24-5.464A12.453 12.453 0 0 1 20 10.673 12.241 12.241 0 0 0 6.959 9.257a12.41 12.41 0 0 0-5.076 4.611A12.604 12.604 0 0 0 0 20.5c0 2.346.653 4.644 1.883 6.632a12.41 12.41 0 0 0 5.076 4.611A12.241 12.241 0 0 0 20 30.327a12.453 12.453 0 0 1-3.48-4.363 12.597 12.597 0 0 1-1.241-5.464Z"
    />
    <path
      fill="#F79E1B"
      d="M40 20.5c0 2.345-.653 4.644-1.883 6.632a12.41 12.41 0 0 1-5.076 4.61 12.241 12.241 0 0 1-6.721 1.187A12.286 12.286 0 0 1 20 30.328a12.477 12.477 0 0 0 3.479-4.365 12.618 12.618 0 0 0 0-10.926A12.477 12.477 0 0 0 20 10.672a12.241 12.241 0 0 1 13.041-1.415 12.41 12.41 0 0 1 5.076 4.611A12.603 12.603 0 0 1 40 20.5Z"
    />
  </BaseSvgExtend>
);

export default Mastercard;
