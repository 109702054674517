import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Mobile = ({ title = 'Mobile', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path
      d="M18.2 32.6H21.8M11 2H29C29.99 2 30.8 2.81 30.8 3.8V36.2C30.8 37.19 29.99 38 29 38H11C10.01 38 9.19998 37.19 9.19998 36.2V3.8C9.19998 2.81 10.01 2 11 2Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.8"
      strokeLinecap="round"
    />
  </BaseSvgExtend>
);

export default Mobile;
