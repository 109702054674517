import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Cookies = ({ title = 'Cookies', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" fill="none" title={title} {...rest}>
    <g fill="currentColor">
      <path d="M21.3977 11.045C22.6577 11.045 23.6746 10.0282 23.6746 8.76812C23.6746 7.50808 22.6577 6.49121 21.3977 6.49121C20.1376 6.49121 19.1208 7.50808 19.1208 8.76812C19.1208 10.0282 20.1376 11.045 21.3977 11.045Z" />
      <path d="M10.7769 17.2769C12.0369 17.2769 13.0538 16.26 13.0538 15C13.0538 13.74 12.0369 12.7231 10.7769 12.7231C9.51687 12.7231 8.5 13.74 8.5 15C8.5 16.26 9.51687 17.2769 10.7769 17.2769Z" />
      <path d="M19.1208 31.5593C20.3808 31.5593 21.3977 30.5424 21.3977 29.2824C21.3977 28.0224 20.3808 27.0055 19.1208 27.0055C17.8607 27.0055 16.8438 28.0224 16.8438 29.2824C16.8438 30.5424 17.8607 31.5593 19.1208 31.5593Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7068 0.804688C19.6502 0.804688 19.5947 0.809394 19.5406 0.818437C19.4619 0.820829 19.3884 0.824422 19.3239 0.827567L19.3155 0.827981C19.1985 0.833685 19.1093 0.837846 19.0216 0.837846H19.0024L18.9833 0.838577C8.90657 1.2239 1 9.51382 1 19.4784C1 19.6179 1.00645 19.7565 1.01171 19.8694L1.01213 19.8785C1.01786 20.0016 1.02211 20.097 1.02211 20.1858V20.2043L1.0228 20.2229C1.39654 30.279 9.67658 38.1852 19.6516 38.1852H19.661C19.8974 38.1852 20.1481 38.1852 20.4131 38.1732C23.0448 38.068 25.5323 37.4384 27.7673 36.3619C29.5557 35.5011 29.9413 33.3002 28.9753 31.7668L28.9745 31.7655C28.2314 30.5897 27.94 29.1903 28.1544 27.8192C28.2325 27.3631 28.3571 26.91 28.5211 26.5184L28.7846 25.8889L28.2945 25.4141C26.7085 23.8773 25.8133 21.7707 25.8133 19.5668C25.8133 17.841 26.5528 15.9665 27.7711 14.4183C28.9906 12.8685 30.6063 11.7505 32.2418 11.4169L32.2459 11.416C34.2555 10.9974 35.2498 8.5861 33.828 6.93325L33.8258 6.93071C30.4011 2.97644 25.3727 0.804688 19.7068 0.804688ZM19.4044 2.82602C19.3015 2.83104 19.1752 2.83721 19.0421 2.8378C10.0499 3.19098 3 10.5924 3 19.4784C3 19.5672 3.00425 19.6625 3.00997 19.7856L3.0104 19.7947C3.0154 19.9022 3.02149 20.0331 3.02206 20.1658C3.36449 29.1347 10.7538 36.1852 19.6516 36.1852C19.898 36.1852 20.1077 36.185 20.3246 36.1752L20.3302 36.1749C22.6933 36.0808 24.9136 35.5165 26.8998 34.5598C27.4543 34.293 27.7097 33.5099 27.2831 32.8328L27.2839 32.834L28.1292 32.2998L27.2831 32.8328C26.2815 31.2474 25.8877 29.3559 26.1798 27.5019L26.1818 27.4897C26.2441 27.1233 26.3318 26.7469 26.4466 26.3791C24.7586 24.5187 23.8133 22.0951 23.8133 19.5668C23.8133 17.3024 24.7649 15.0044 26.1993 13.1815C27.632 11.3608 29.6297 9.90936 31.8396 9.45773C32.4809 9.32304 32.6579 8.64126 32.3126 8.23848C29.3329 4.79896 24.9341 2.84189 19.8525 2.80521C19.8049 2.81215 19.7563 2.81574 19.7068 2.81574C19.6191 2.81574 19.5299 2.8199 19.4129 2.82561L19.4044 2.82602Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.6013 21.85C37.449 21.8283 37.1576 21.8073 36.846 21.9408L36.8386 21.944L36.8313 21.9473C32.8324 23.7377 31.2646 26.2815 30.9445 28.7396C30.6421 31.062 31.4822 33.1051 31.9352 34.0165C32.035 34.2548 32.1919 34.4262 32.3352 34.5408L32.3877 34.5829L32.4454 34.6175L32.4522 34.6216C32.4967 34.6493 32.6959 34.7731 32.9555 34.8164C33.0936 34.8394 33.3507 34.864 33.6324 34.7596C33.8838 34.6696 34.0534 34.5197 34.1404 34.4336C35.5486 33.0672 36.6899 31.3876 37.5077 29.497L37.5096 29.4926C38.3176 27.6001 38.7836 25.5421 38.8789 23.4351L38.8799 23.4126V23.39C38.8799 23.1366 38.82 22.9041 38.7143 22.6928C38.6338 22.5317 38.5072 22.336 38.3101 22.1717C38.1429 22.0325 37.8957 21.8921 37.6013 21.85ZM35.6711 28.7052C36.2769 27.2859 36.6668 25.7545 36.8216 24.175C34.0171 25.6591 33.1268 27.4696 32.9278 28.9979C32.7619 30.2717 33.0597 31.476 33.3823 32.3343C34.3066 31.2923 35.0811 30.0685 35.6711 28.7052Z"
      />
    </g>
  </BaseSvgExtend>
);

export default Cookies;
