import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { FontWeight } from '@/constants';
import { ElementType } from 'react';
import styles from '@/newComponents/Typography/Typography.module.css';

export const getBaseStyle = (type: TypographyType, elementAs?: ElementType) => {
  switch (type) {
    case TypographyType.Display:
      return {
        className: styles.display,
        as: elementAs || 'h1',
        defaultWeight: FontWeight.SemiBold,
      };
    case TypographyType.HeadingL:
      return {
        className: styles.headingL,
        as: elementAs || 'h1',
        defaultWeight: FontWeight.SemiBold,
      };
    case TypographyType.HeadingM:
      return {
        className: styles.headingM,
        as: elementAs || 'h2',
        defaultWeight: FontWeight.SemiBold,
      };
    case TypographyType.HeadingS:
      return {
        className: styles.headingS,
        as: elementAs || 'h3',
        defaultWeight: FontWeight.SemiBold,
      };
    case TypographyType.ExpressiveL:
      return {
        className: styles.expressiveL,
        as: elementAs || 'h3',
        defaultWeight: FontWeight.Medium,
      };
    case TypographyType.ExpressiveS:
      return {
        className: styles.expressiveS,
        as: elementAs || 'h3',
        defaultWeight: FontWeight.Medium,
      };
    case TypographyType.CappedHeading:
      return {
        className: styles.cappedHeading,
        as: elementAs || 'h3',
        defaultWeight: FontWeight.SemiBold,
      };
    case TypographyType.Detail:
      return {
        className: styles.detail,
        as: elementAs || 'span',
        defaultWeight: FontWeight.Regular,
      };
    case TypographyType.Micro:
      return {
        className: styles.micro,
        as: elementAs || 'span',
        defaultWeight: FontWeight.Regular,
      };
    case TypographyType.Body:
    default:
      return {
        className: styles.body,
        as: elementAs || 'span',
        defaultWeight: FontWeight.Regular,
      };
  }
};
