import { FontWeight } from '@/constants';
import { ReactNode } from 'react';

export enum TypographyType {
  /** size: 88, line-height: 88, font-weight: 600, as: h1 */
  Display = 'Display',
  /** size: 54, line-height: 60, font-weight: 600, as: h1 */
  HeadingL = 'HeadingL',
  /** size: 38, line-height: 44, font-weight: 600, as: h2 */
  HeadingM = 'HeadingM',
  /** size: 32, line-height: 36, font-weight: 600, as: h3 */
  HeadingS = 'HeadingS',
  /** size: 24, line-height: 32, font-weight: 500/600, as: h2 */
  ExpressiveL = 'ExpressiveL',
  /** size: 20, line-height: 24, font-weight: 500/600, as: h3 */
  ExpressiveS = 'ExpressiveS',
  /** size: 18, line-height: 28, font-weight: 600, as: h3 */
  CappedHeading = 'CappedHeading',
  /** size: 16, line-height: 24, font-weight 400/500/600 */
  Body = 'Body',
  /** size: 14, line-height: 20, font-weight 400/500/600 */
  Detail = 'Detail',
  /** size: 12, line-height: 16, font-weight 400/500/600 */
  Micro = 'Micro',
}

export type FontProps = {
  lineHeight: string;
  size: string;
} | null;

export type TextProps = {
  type: TypographyType;
  desktop: FontProps;
  mobile?: FontProps;
  allowedWeights: FontWeight[];
  defaultWeight: FontWeight;
};

/** This gives weird inline error, would be nice to custom VSCode error :S */
// Used for prop text
type TextNoChildren = {
  text: ReactNode;
  children?: never;
};

// Used for HtmlFromCMS
type ChildrenNoText = {
  children: ReactNode;
  text?: never;
};

export type EitherTextOrChildren = TextNoChildren | ChildrenNoText;

type TypographyBlack = {
  type?: TypographyType.Display | TypographyType.HeadingL | TypographyType.CappedHeading;
  fontWeight?: FontWeight.SemiBold;
};

type TypographyBlackOrBold = {
  type?: TypographyType.HeadingM | TypographyType.HeadingS;
  fontWeight?: FontWeight.SemiBold | FontWeight.Medium;
};

type TypographyAny = {
  type?:
    | TypographyType.ExpressiveL
    | TypographyType.ExpressiveS
    | TypographyType.Detail
    | TypographyType.Body
    | TypographyType.Micro;
  fontWeight?: FontWeight.Regular | FontWeight.Medium | FontWeight.SemiBold;
};

type TypographyDisplay = TypographyBlack;
type TypographyHeadingL = TypographyBlack;
type TypographyHeadingM = TypographyBlackOrBold;
type TypographyHeadingS = TypographyBlackOrBold;
type TypographyExpressiveL = TypographyAny;
type TypographyExpressiveS = TypographyAny;
type TypographyCappedHeading = TypographyBlack;
type TypographyDetail = TypographyAny;
type TypographyMicro = TypographyAny;
type TypographyBody = TypographyAny;

export type TypographyTypeAndWeight =
  | TypographyDisplay
  | TypographyHeadingL
  | TypographyHeadingM
  | TypographyHeadingS
  | TypographyExpressiveL
  | TypographyExpressiveS
  | TypographyCappedHeading
  | TypographyDetail
  | TypographyMicro
  | TypographyBody;
