'use client';

import { IconBg, IconSizeKey, IconWrapper } from '@/newComponents/SvgIcon/SvgIcon.styled';
import React from 'react';

import { Color } from '@/global/style/variables';
import AdyenLogo from '@/global/svg/AdyenLogo';
import Aeroplane from '@/global/svg/Aeroplane';
import AlertCircle from '@/global/svg/AlertCircle';
import AlertTriangle from '@/global/svg/AlertTriangle';
import BillogramLogo from '@/global/svg/BillogramLogo';
import BroadbandPause from '@/global/svg/BroadbandPause';
import Calendar from '@/global/svg/Calendar';
import CameraSight from '@/global/svg/CameraSight';
import Card from '@/global/svg/Card';
import Chat from '@/global/svg/Chat';
import Check from '@/global/svg/Check';
import ChevronDown from '@/global/svg/ChevronDown';
import ChevronLeft from '@/global/svg/ChevronLeft';
import ChevronRight from '@/global/svg/ChevronRight';
import ChevronUp from '@/global/svg/ChevronUp';
import Confetti from '@/global/svg/Confetti';
import Cookies from '@/global/svg/Cookies';
import Cross from '@/global/svg/Cross';
import CustomerService from '@/global/svg/CustomerService';
import Delivery from '@/global/svg/Delivery';
import Denied from '@/global/svg/Denied';
import Details from '@/global/svg/Details';
import Dinerscard from '@/global/svg/DinersCard';
import Document from '@/global/svg/Document';
import Download from '@/global/svg/Download';
import Edit from '@/global/svg/Edit';
import Esim from '@/global/svg/Esim';
import EuFlag from '@/global/svg/EuFlag';
import ExternalLink from '@/global/svg/ExternalLink';
import Facebook from '@/global/svg/Facebook';
import Gift from '@/global/svg/Gift';
import HallonApp from '@/global/svg/HallonApp';
import HallonLogoCompany from '@/global/svg/HallonLogoCompany';
import Heart from '@/global/svg/Heart';
import Help from '@/global/svg/Help';
import Instagram from '@/global/svg/Instagram';
import Instalments from '@/global/svg/Instalments';
import Like from '@/global/svg/Like';
import LimitCosts from '@/global/svg/LimitCosts';
import Linkedin from '@/global/svg/Linkedin';
import Lock from '@/global/svg/Lock';
import LockedQrCode from '@/global/svg/LockedQrCode';
import Login from '@/global/svg/Login';
import Mastercard from '@/global/svg/Mastercard';
import Mobile from '@/global/svg/Mobile';
import MobileSubscription from '@/global/svg/MobileSubscription';
import MultiColorCheckmark, { MultiColorCheckmarkProps } from '@/global/svg/MultiColorCheckmark';
import Music from '@/global/svg/Music';
import MyPages from '@/global/svg/MyPages';
import NumberMove from '@/global/svg/NumberMove';
import Package from '@/global/svg/Package';
import Paperplane from '@/global/svg/Paperplane';
import Pause from '@/global/svg/Pause';
import Phone from '@/global/svg/Phone';
import PhoneNumber from '@/global/svg/PhoneNumber';
import PiggyBank from '@/global/svg/PiggyBank';
import Raspberry from '@/global/svg/Raspberry';
import Recycled from '@/global/svg/Recycled';
import Refresh from '@/global/svg/Refresh';
import Returns from '@/global/svg/Returns';
import RivertyLogo from '@/global/svg/RivertyLogo';
import Runner from '@/global/svg/Runner';
import Sharing from '@/global/svg/Sharing';
import ShoppingBag from '@/global/svg/ShoppingBag';
import SimExtra from '@/global/svg/SimExtra';
import SimCard from '@/global/svg/Simcard';
import Slash from '@/global/svg/Slash';
import SmallArrow from '@/global/svg/SmallArrow';
import StorageCard from '@/global/svg/StorageCard';
import Switch from '@/global/svg/Switch';
import ThumbsUp from '@/global/svg/ThumbsUp';
import Tool from '@/global/svg/Tool';
import Unlock from '@/global/svg/Unlock';
import User from '@/global/svg/User';
import Video from '@/global/svg/Video';
import VisaCard from '@/global/svg/VisaCard';
import VisaElectronCard from '@/global/svg/VisaElectronCard';
import VoicePause from '@/global/svg/VoicePause';
import Voicemail from '@/global/svg/Voicemail';
import Wallet from '@/global/svg/Wallet';
import Warranty from '@/global/svg/Warranty';
import Wifi from '@/global/svg/Wifi';
import WifiOff from '@/global/svg/WifiOff';
import XOctagon from '@/global/svg/XOctagon';
import BankId from '@/global/svg/bankid';
import HallonLogo from '@/global/svg/hallonlogo';
import Logout from '@/global/svg/logout';
import { HallonColor } from '@/global/utils/styleTypes';
import Unlimited from '@/global/svg/Unlimited';
import { HallonSvgIconProps } from '@/types/icon';

export const hallonIcons = {
  adyenLogo: (props: HallonSvgIconProps) => <AdyenLogo {...props} />,
  aeroplane: (props: HallonSvgIconProps) => <Aeroplane {...props} />,
  'alert-circle': (props: HallonSvgIconProps) => <AlertCircle {...props} />,
  'alert-triangle': (props: HallonSvgIconProps) => <AlertTriangle {...props} />,
  app: (props: HallonSvgIconProps) => <HallonApp {...props} />,
  bankId: (props: HallonSvgIconProps) => <BankId {...props} />,
  billogramLogo: (props: HallonSvgIconProps) => <BillogramLogo {...props} />,
  broadbandPause: (props: HallonSvgIconProps) => <BroadbandPause {...props} />,
  calendar: (props: HallonSvgIconProps) => <Calendar {...props} />,
  calls: (props: HallonSvgIconProps) => <Phone {...props} />,
  cameraSight: (props: HallonSvgIconProps) => <CameraSight {...props} />,
  card: (props: HallonSvgIconProps) => <Card {...props} />,
  chat: (props: HallonSvgIconProps) => <Chat {...props} />,
  'check-circle': (props: HallonSvgIconProps) => <Check {...props} />,
  check: (props: HallonSvgIconProps) => <Check {...props} />,
  'chevron-down': (props: HallonSvgIconProps) => <ChevronDown {...props} />,
  'chevron-left': (props: HallonSvgIconProps) => <ChevronLeft {...props} />,
  'chevron-right': (props: HallonSvgIconProps) => <ChevronRight {...props} />,
  'chevron-up': (props: HallonSvgIconProps) => <ChevronUp {...props} />,
  confetti: (props: HallonSvgIconProps) => <Confetti {...props} />,
  cookies: (props: HallonSvgIconProps) => <Cookies {...props} />, // Could be removed if Not used by CMS
  cross: (props: HallonSvgIconProps) => <Cross {...props} />,
  customerService: (props: HallonSvgIconProps) => <CustomerService {...props} />, // Could be removed if Not used by CMS
  delivery: (props: HallonSvgIconProps) => <Delivery {...props} />, // Could be removed if Not used by CMS
  denied: (props: HallonSvgIconProps) => <Denied {...props} />,
  details: (props: HallonSvgIconProps) => <Details {...props} />,
  dinersCard: (props: HallonSvgIconProps) => <Dinerscard {...props} />,
  document: (props: HallonSvgIconProps) => <Document {...props} />,
  download: (props: HallonSvgIconProps) => <Download {...props} />,
  edit: (props: HallonSvgIconProps) => <Edit {...props} />,
  esim: (props: HallonSvgIconProps) => <Esim {...props} />,
  error: (props: HallonSvgIconProps) => <Slash {...props} />,
  euFlag: (props: HallonSvgIconProps) => <EuFlag {...props} />,
  'external-link': (props: HallonSvgIconProps) => <ExternalLink {...props} />,
  facebook: (props: HallonSvgIconProps) => <Facebook {...props} />,
  gift: (props: HallonSvgIconProps) => <Gift {...props} />,
  greenCheck: (props: HallonSvgIconProps) => <Check {...props} />, // Could be removed if Not used by CMS
  hallonLogo: (props: HallonSvgIconProps) => <HallonLogo {...props} />,
  hallonLogoCompany: (props: HallonSvgIconProps) => <HallonLogoCompany {...props} />,
  heart: (props: HallonSvgIconProps) => <Heart {...props} />,
  'help-circle': (props: HallonSvgIconProps) => <Help {...props} />,
  help: (props: HallonSvgIconProps) => <Help {...props} />,
  info: (props: HallonSvgIconProps) => <AlertCircle {...props} />,
  instagram: (props: HallonSvgIconProps) => <Instagram {...props} />,
  instalments: (props: HallonSvgIconProps) => <Instalments {...props} />,
  like: (props: HallonSvgIconProps) => <Like {...props} />,
  'limit-costs': (props: HallonSvgIconProps) => <LimitCosts {...props} />, // duplicate LimitCost, but is key used by CMS
  limitcosts: (props: HallonSvgIconProps) => <LimitCosts {...props} />,
  linkedin: (props: HallonSvgIconProps) => <Linkedin {...props} />,
  lock: (props: HallonSvgIconProps) => <Lock {...props} />,
  lockedQrCode: (props: HallonSvgIconProps) => <LockedQrCode {...props} />,
  'log-in': (props: HallonSvgIconProps) => <Login {...props} />,
  logout: (props: HallonSvgIconProps) => <Logout {...props} />,
  mastercard: (props: HallonSvgIconProps) => <Mastercard {...props} />,
  mobile: (props: HallonSvgIconProps) => <Mobile {...props} />,
  'mobile-subscription': (props: HallonSvgIconProps) => <MobileSubscription {...props} />,
  'multi-color-checkmark': (props: MultiColorCheckmarkProps) => <MultiColorCheckmark {...props} />,
  music: (props: HallonSvgIconProps) => <Music {...props} />,
  myPages: (props: HallonSvgIconProps) => <MyPages {...props} />,
  'number-move': (props: HallonSvgIconProps) => <NumberMove {...props} />, // duplicate NumberMove, but is key used by CMS
  numberMove: (props: HallonSvgIconProps) => <NumberMove {...props} />,
  package: (props: HallonSvgIconProps) => <Package {...props} />,
  paperplane: (props: HallonSvgIconProps) => <Paperplane {...props} />,
  pause: (props: HallonSvgIconProps) => <Pause {...props} />,
  'phone-extra': (props: HallonSvgIconProps) => <SimExtra {...props} />,
  phone: (props: HallonSvgIconProps) => <Mobile {...props} />, // we always show mobile icon, for phone too
  phonenumber: (props: HallonSvgIconProps) => <PhoneNumber {...props} />,
  'piggy-bank': (props: HallonSvgIconProps) => <PiggyBank {...props} />,
  porting: (props: HallonSvgIconProps) => <NumberMove {...props} />, // duplicate NumberMove, but is key used by CMS
  priceList: (props: HallonSvgIconProps) => <Document {...props} />,
  questionmark: (props: HallonSvgIconProps) => <Help {...props} />,
  raspberry: (props: HallonSvgIconProps) => <Raspberry {...props} />,
  recycled: (props: HallonSvgIconProps) => <Recycled {...props} />,
  refresh: (props: HallonSvgIconProps) => <Refresh {...props} />,
  returns: (props: HallonSvgIconProps) => <Returns {...props} />,
  rivertyLogo: (props: HallonSvgIconProps) => <RivertyLogo {...props} />,
  router: (props: HallonSvgIconProps) => <Wifi {...props} />, // duplicate Wifi, but is key used by CMS
  runner: (props: HallonSvgIconProps) => <Runner {...props} />,
  sharing: (props: HallonSvgIconProps) => <Sharing {...props} />,
  shoppingBag: (props: HallonSvgIconProps) => <ShoppingBag {...props} />,
  sim: (props: HallonSvgIconProps) => <SimCard {...props} />,
  slash: (props: HallonSvgIconProps) => <Slash {...props} />,
  smallArrow: (props: HallonSvgIconProps) => <SmallArrow {...props} />,
  'smartphone-extra': (props: HallonSvgIconProps) => <SimExtra {...props} />,
  smartphone: (props: HallonSvgIconProps) => <Mobile {...props} />, // duplicate Phone, but is key used by CMS
  storageCard: (props: HallonSvgIconProps) => <StorageCard {...props} />,
  switch: (props: HallonSvgIconProps) => <Switch {...props} />,
  'thumbs-up': (props: HallonSvgIconProps) => <ThumbsUp {...props} />,
  tool: (props: HallonSvgIconProps) => <Tool {...props} />,
  unlimited: (props: HallonSvgIconProps) => <Unlimited {...props} />,
  unlocked: (props: HallonSvgIconProps) => <Unlock {...props} />,
  user: (props: HallonSvgIconProps) => <User {...props} />,
  video: (props: HallonSvgIconProps) => <Video {...props} />,
  visaCard: (props: HallonSvgIconProps) => <VisaCard {...props} />,
  visaElectronCard: (props: HallonSvgIconProps) => <VisaElectronCard {...props} />,
  voicemail: (props: HallonSvgIconProps) => <Voicemail {...props} />,
  voicePause: (props: HallonSvgIconProps) => <VoicePause {...props} />,
  wallet: (props: HallonSvgIconProps) => <Wallet {...props} />,
  warranty: (props: HallonSvgIconProps) => <Warranty {...props} />,
  wifi: (props: HallonSvgIconProps) => <Wifi {...props} />,
  'wifi-off': (props: HallonSvgIconProps) => <WifiOff {...props} />,
  xoctagon: (props: HallonSvgIconProps) => <XOctagon {...props} />,
};

type HallonIconKey = keyof typeof hallonIcons;
export type IconKey = HallonIconKey;

const iconHasCheckmarkColor = (icon: IconKey): boolean => {
  return ['multi-color-checkmark', 'check', 'check-circle', 'greenCheck'].includes(icon);
};

export type SvgIconProps = {
  icon: IconKey;
  color?: string;
  title?: string;
  bgColor?: HallonColor;
  size?: IconSizeKey | string;
  circleColor?: HallonColor;
  boxColor?: HallonColor;
  fill?: HallonColor;
  alt?: string;
  checkmarkColor?: HallonColor;
  className?: string;
  ariaHidden?: boolean;
  backgroundAriaLabel?: string;
};

const SvgIcon = (props: SvgIconProps) => {
  const {
    icon,
    color,
    size = 'large',
    title = icon,
    bgColor,
    alt,
    checkmarkColor = Color.White,
    circleColor,
    className = '',
    ariaHidden = true,
    backgroundAriaLabel,
    ...rest
  } = props;

  const HallonIconComponent = hallonIcons[icon as HallonIconKey];
  const hasCheckmarkColor = iconHasCheckmarkColor(icon);
  return (
    <IconWrapper $color={color} $size={size} $bgColor={bgColor}>
      {bgColor && <IconBg $size={size} $bgColor={bgColor} aria-label={backgroundAriaLabel} />}
      <HallonIconComponent
        className={className}
        alt={alt}
        title={title}
        ariaHidden={ariaHidden}
        {...(hasCheckmarkColor ? { checkmarkColor, circleColor } : {})}
        {...rest}
      />
    </IconWrapper>
  );
};

export default SvgIcon;
