import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const ThumbsUp = ({ title = 'Thumbs up', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" fill="none" title={title} {...rest}>
    <path
      d="M11.3911 18.1999L18.5039 2C19.9188 2 21.2757 2.56892 22.2761 3.58161C23.2766 4.5943 23.8386 5.96781 23.8386 7.39997V14.5999H33.9033C34.4188 14.594 34.9294 14.7016 35.3998 14.9153C35.8701 15.129 36.289 15.4436 36.6273 15.8374C36.9656 16.2312 37.2152 16.6948 37.359 17.196C37.5027 17.6971 37.5371 18.2239 37.4597 18.7399L35.0058 34.9398C34.8772 35.7982 34.4464 36.5807 33.7929 37.143C33.1393 37.7053 32.307 38.0096 31.4494 37.9998H11.3911M11.3911 18.1999V37.9998M11.3911 18.1999H6.05643C5.11321 18.1999 4.20862 18.5792 3.54165 19.2543C2.87469 19.9294 2.5 20.8451 2.5 21.7999V34.3998C2.5 35.3546 2.87469 36.2702 3.54165 36.9454C4.20862 37.6205 5.11321 37.9998 6.05643 37.9998H11.3911"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseSvgExtend>
);

export default ThumbsUp;
