'use client';

import { spacing } from '@/global/style/variables';

import { Component } from './Divider.styled';
import { DividerProps } from './Divider.types';

/**
 * DEPRECATED, implement own padding/margin where needed in app router.
 */
const Divider = (props: DividerProps) => {
  const {
    className = '',
    color = 'transparent',
    margin = undefined,
    marginY = spacing.x1,
    marginYSm,
    width = '100%',
  } = props;

  return (
    <Component
      className={className}
      $color={color}
      $margin={margin}
      $marginY={marginY}
      $marginYSm={marginYSm}
      $width={width}
    />
  );
};

export default Divider;
