import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const SmallArrow = ({ title = 'Small arrow', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <path
      d="M29.4845 22.273L15.4949 34.7404C15.0615 35.1267 14.527 35.3784 13.9559 35.4657C13.3847 35.5531 12.8006 35.4726 12.2735 35.2336C11.7463 34.9946 11.2982 34.6072 10.9834 34.1172C10.6687 33.6273 10.5007 33.0559 10.5 32.4716V7.53168C10.5 7.53167 10.5 7.53166 10.5 7.53164C10.5 6.94702 10.6676 6.37513 10.9822 5.88468C11.2968 5.39427 11.7448 5.00636 12.2721 4.767C12.7994 4.52767 13.3837 4.44689 13.9551 4.53416C14.5266 4.62143 15.0613 4.87313 15.4949 5.25957L29.4845 17.7288C29.8034 18.013 30.059 18.3625 30.2342 18.7543C30.4094 19.146 30.5 19.5709 30.5 20.0009C30.5 20.4308 30.4094 20.8558 30.2342 21.2475C30.059 21.6392 29.8034 21.9887 29.4845 22.273C29.4845 22.273 29.4845 22.273 29.4845 22.273Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </BaseSvgExtend>
);

export default SmallArrow;
