import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Confetti = ({ title = 'Confetti', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="100" height="200" viewBox="0 0 20 40" title={title} {...rest}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path
        fill="#F5D84F"
        d="M13.851 25.52h2.172v2.172h-2.172V25.52ZM10.79 29.246l.783-.453.453.784-.784.452-.452-.783ZM16.35 1.4l1.531.557-.557 1.53-1.53-.557.557-1.53Z"
      />
      <path
        fill="#8D173F"
        d="m7.195 16.27-.74 1.452-2.419-1.233.74-1.451 2.419 1.232ZM15.782 13.183l-.368-.827 2.48-1.104.368.826-2.48 1.105Z"
      />
      <path fill="#F5D84F" d="m6.806.938.545-.723 4.047 3.05-.544.723L6.806.938Z" />
      <path
        fill="#8D173F"
        d="m13.282 19.432.759.493-.888 1.366-.758-.493.887-1.366ZM1.178 6.485l-.475.264L.09 5.64l.475-.263.614 1.108ZM17 36.79l1.425-.79 1.316 2.374-1.425.79L17 36.79Z"
      />
    </g>
  </BaseSvgExtend>
);

export default Confetti;
