import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';
import { Color } from '@/global/style/variables';

const Denied = ({ title = 'Denied', fill = Color.Hallon5, ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <g fill={fill}>
      <path d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20Z" />
      <path
        fill="#630132"
        d="M12.857 18.57a2.857 2.857 0 1 0 0-5.713 2.857 2.857 0 0 0 0 5.714ZM27.143 18.57a2.857 2.857 0 1 0 0-5.713 2.857 2.857 0 0 0 0 5.714Z"
      />
      <path stroke="#630132" strokeWidth={2.857} d="M25.714 28.57a5.714 5.714 0 0 0-11.428 0" />
    </g>
  </BaseSvgExtend>
);

export default Denied;
