import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Instagram = ({ title = 'Instagram', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="40" height="40" viewBox="0 0 40 40" title={title} {...rest}>
    <g stroke="currentColor" fill="none">
      <path
        d="M8.75 2H31.25C34.9625 2 38 5.0375 38 8.75V31.25C38 34.9625 34.9625 38 31.25 38H8.75C5.0375 38 2 34.9625 2 31.25V8.75C2 5.0375 5.0375 2 8.75 2Z"
        strokeWidth="2"
      />
      <path
        d="M20 29C24.9706 29 29 24.9706 29 20C29 15.0294 24.9706 11 20 11C15.0294 11 11 15.0294 11 20C11 24.9706 15.0294 29 20 29Z"
        strokeWidth="2"
      />
    </g>
  </BaseSvgExtend>
);

export default Instagram;
