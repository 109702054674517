import { HallonSvgIconProps } from '@/types/icon';

import BaseSvgExtend from '@/global/style/extends/BaseSvg';

const Like = ({ title = 'Like', ...rest }: HallonSvgIconProps) => (
  <BaseSvgExtend width="45" height="46" viewBox="0 0 45 46" title={title} {...rest}>
    <g fill="currentColor">
      <path
        d="M35.4274 28.6447C33.7206 28.008 32.7146 26.4583 31.9816 24.8641C31.1565 23.064 30.5334 21.1639 30.1072 19.2341C29.2087 15.2047 29.2262 10.971 30.1179 6.94176C30.4665 5.35945 30.8213 3.28362 29.0035 2.44614C27.5313 1.76946 25.9311 2.56727 24.9877 3.72707C23.7863 5.21087 22.9877 7.14074 22.3576 8.92465C21.7248 10.7089 21.2642 12.5806 21.0342 14.4712C20.838 16.1156 20.3841 18.5404 21.556 19.9221C22.6034 21.1521 24.5249 20.9301 25.0215 19.3296C25.5655 17.5924 24.0952 16.3197 22.838 15.3995C21.3642 14.3249 19.7732 13.4067 18.0131 12.9018C14.9438 12.0287 11.2501 12.8497 9.4932 15.7046C7.59942 18.7823 9.45327 22.544 12.2688 24.2751C13.973 25.3214 16.0496 25.7735 18.0328 25.8502C19.5965 25.9115 21.501 25.4828 22.0831 23.8301C22.7852 21.8396 21.1304 20.1748 19.4974 19.3675C17.4435 18.353 15.1252 17.9278 12.8397 18.0191C11.8615 18.0557 11.9668 19.5712 12.9451 19.5346C14.6157 19.4686 16.288 19.7115 17.8509 20.3102C18.9816 20.7449 21.1059 21.6756 20.6778 23.2454C20.361 24.4062 18.6385 24.3894 17.6886 24.3167C16.2381 24.2025 14.7736 23.884 13.4775 23.2136C11.1112 21.9896 9.19248 18.9235 10.8513 16.3813C12.3094 14.1311 15.3055 13.7047 17.7034 14.393C19.1197 14.7954 20.4372 15.5495 21.6356 16.399C22.1725 16.7813 22.7844 17.185 23.2176 17.697C23.4688 17.9974 23.9809 19.062 23.2739 19.2212C21.9009 19.5346 22.4242 15.6341 22.492 15.03C22.6583 13.5508 22.9512 12.0811 23.3779 10.659C23.8326 9.12493 24.4552 7.64261 25.2037 6.23391C25.7577 5.19151 26.6357 3.49966 28.0712 3.74099C29.6366 3.997 28.6503 6.48444 28.4669 7.43957C28.1625 9.01923 27.9925 10.6269 27.9451 12.2363C27.8415 15.7456 28.3657 19.2892 29.4764 22.6216C30.4056 25.4056 31.7665 28.8938 34.7588 30.0018C35.6663 30.3414 36.351 28.9795 35.4325 28.6413L35.4195 28.6485L35.4274 28.6447Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M9.96601 20.5294C8.23213 20.5335 6.92086 22.0976 6.87747 23.7621C6.82638 25.6587 8.17437 27.2053 9.72736 28.1087C11.3943 29.0788 13.4102 29.6024 15.307 29.8372C17.2038 30.072 19.3145 30.1887 20.6803 28.7293C21.9335 27.395 21.5587 24.7963 19.4961 24.5485C18.5326 24.4329 18.2198 25.9217 19.197 26.0356C19.8302 26.1138 20.0739 26.7603 19.8273 27.314C19.517 28.0063 18.7143 28.3164 18.0163 28.4133C16.321 28.6409 14.4499 28.2526 12.8257 27.7672C11.32 27.3174 9.56165 26.6229 8.75528 25.1796C8.08785 23.9838 8.3885 22.0566 10.061 22.0517C11.0409 22.0511 10.9294 20.5308 9.9557 20.5362L9.96052 20.5301L9.96601 20.5294Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M8.54438 25.8211C7.22068 26.3316 6.69751 27.8296 7.06205 29.1434C7.47489 30.6238 8.94638 31.4983 10.2433 32.1074C11.8802 32.878 13.6416 33.4384 15.4331 33.7223C17.029 33.9774 18.8066 33.8983 19.9856 32.64C21.1646 31.3817 20.9572 29.3526 19.4297 28.5435C18.5632 28.0847 17.8899 29.448 18.756 29.904C19.1846 30.1298 19.328 30.6857 19.1773 31.1357C18.9845 31.6967 18.3935 32.0532 17.8509 32.2145C16.4133 32.636 14.6207 32.071 13.2384 31.6505C11.8584 31.227 10.3536 30.6935 9.27401 29.7682C8.86008 29.4126 8.50702 28.9632 8.47465 28.4048C8.44377 27.9492 8.59777 27.4348 9.05261 27.262C9.9592 26.9141 9.46503 25.4742 8.55088 25.8286L8.54371 25.8156L8.54438 25.8211Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M9.57456 30.3302C8.32668 30.9595 7.77967 32.3769 8.27373 33.7026C8.76746 35.0256 10.0705 35.8427 11.3824 36.3025C12.8242 36.7991 14.349 37.1325 15.8726 37.2294C17.1799 37.3111 18.6114 37.0212 19.3098 35.794C19.968 34.647 19.7006 33.0818 18.6914 32.2314C17.9405 31.6025 16.9424 32.7468 17.6937 33.3784C18.2619 33.857 18.331 34.8507 17.7435 35.3488C16.868 36.0855 15.234 35.6792 14.2395 35.4923C13 35.2603 11.5317 34.9785 10.5038 34.2027C10.0987 33.8961 9.71645 33.4586 9.62505 32.9409C9.55394 32.5432 9.68748 31.9756 10.08 31.7715C10.4444 31.5875 10.6947 31.2227 10.5428 30.8015C10.4219 30.4517 9.94476 30.1484 9.57282 30.3388L9.57215 30.3333L9.57456 30.3302Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M34.6607 30.0836C36.2313 30.6091 38.2914 30.473 39.186 28.871C39.9282 27.5466 39.5376 25.7711 37.9957 25.2756C36.1034 24.67 34.4885 26.2546 33.3933 27.5834C32.2298 28.9902 31.3546 30.6149 30.7823 32.3471C29.9249 34.9329 28.9636 40.7524 32.6328 41.705C34.214 42.1122 35.7511 41.027 35.6464 39.3361C35.5292 37.4518 33.7755 36.5007 32.2195 35.89C27.9559 34.2003 23.2906 33.8878 18.8299 34.9004C17.8821 35.117 18.3893 36.5497 19.3315 36.3338C22.1022 35.7041 24.9881 35.6504 27.7781 36.1764C29.1543 36.4334 30.5014 36.8164 31.7923 37.3539C32.6634 37.7146 34.6532 38.57 34.0667 39.8697C33.8728 40.3083 33.338 40.3517 32.9352 40.223C32.2893 40.0183 31.952 39.4027 31.7769 38.7923C31.4278 37.6047 31.5189 36.2154 31.7042 35.0039C32.0875 32.4569 33.1998 30.0431 34.9327 28.1266C35.4081 27.6005 35.9685 26.9721 36.6709 26.7523C37.2735 26.5586 38.0385 26.7124 38.0523 27.4596C38.0854 29.0897 36.005 28.947 34.9656 28.5986C34.0419 28.2861 33.7491 29.778 34.6666 30.0857L34.6659 30.0802L34.6607 30.0836Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </g>
  </BaseSvgExtend>
);

export default Like;
